import React, { useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { InputWrapper, Select } from '@amzn/stencil-react-components/form';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import {
  buildUrl,
  CANDIDATE_SUPPORT_LINK,
  SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_COMPLETE_URL,
  SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getFormattedText, getText } from 'src/utility/common';
import { getLocale } from 'src/utility/locale-data';
import {
  cancelScheduleReasons,
  Reason,
  Schedule,
  updateOrCancelSchedule,
  UpdateScheduleRequest,
} from 'src/utility/schedule-data';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import { BackButton } from '../custom-button/back-button';
import { ScheduleContainer } from '../schedule-card/schedule-container';

interface ScheduleCancelConfirmationControlledProps {
  loading: boolean;
  error: boolean;
  details?: Schedule;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleCancelConfirmationControlled({
  loading,
  error,
  details,
  urlProps,
  bundle,
  stage,
}: ScheduleCancelConfirmationControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(
        bundle,
        'banner-error-current-schedule-fails-to-retrieve',
        {
          support: supportLink,
        },
      )}
    </MessageBanner>
  );

  if (error || !details) {
    return errorMessage;
  }

  const [updating, setUpdating] = useState<boolean>(false);
  const [reason, setReason] = useState<Reason>();
  const [reasonError, setReasonError] = useState<boolean>(false);

  const getFooterText = () => {
    if (reasonError) {
      return getText(bundle, 'banner-error-reason-not-selected');
    }
    return undefined;
  };

  const urlToCancelScheduleCompletePage = buildUrl(
    SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_COMPLETE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const urlToUpdateScheduleFailurePage = buildUrl(
    SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const urlToCurrentSchedulePage = buildUrl(
    SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const sendAdobeEvents = (eventName: string, reason: string) => {
    adobeAnalytics.addEventMetric(
      window,
      eventName,
      urlProps.applicationId as string,
      urlProps.jobId as string,
      {
        cancellationReschedule: {
          reason,
        },
      },
    );
  };

  const cancelSchedule = async () => {
    if (!reason) {
      setReasonError(true);
    } else {
      setUpdating(true);
      try {
        const request = {
          applicationId: urlProps.applicationId,
          jobId: urlProps.jobId,
          locale: getLocale(),
        } as UpdateScheduleRequest;
        const response = await updateOrCancelSchedule(request);
        if (response?.errorCode) {
          sendAdobeEvents(
            'fail to cancel shift on self service page',
            reason.value,
          );
          window.location.assign(urlToUpdateScheduleFailurePage);
        } else {
          sendAdobeEvents(
            'successfully cancel shift on self service page',
            reason.value,
          );
          window.location.assign(urlToCancelScheduleCompletePage);
        }
      } catch (err) {
        sendAdobeEvents(
          'fail to cancel shift on self service page',
          reason.value,
        );
        window.location.assign(urlToUpdateScheduleFailurePage);
      }
    }
  };

  const confirmation = (
    <Col gridGap="1rem">
      <Row alignItems="center">
        <BackButton url={urlToCurrentSchedulePage} bundle={bundle} />
      </Row>
      <Col gridGap="1.5rem">
        <ScheduleContainer
          schedule={details}
          displayPreviewInfo={false}
          title={getText(bundle, 'heading-page-cancel-schedule')}
          bundle={bundle}
          stage={stage}
        />
        <Col gridGap="1rem" className="cancellation-confirmation">
          <Text>
            <strong>
              {getText(bundle, 'confirmation-question-page-cancel-schedule')}
            </strong>
          </Text>
          <Text>{getText(bundle, 'description-page-cancel-schedule')}</Text>
        </Col>
        <Col
          gridGap="0.5rem"
          className="schedule-cancellation-reason-selection"
        >
          <InputWrapper
            id="cancel-schedule-reason-wrapper"
            labelText={getText(bundle, 'reason-question-page-cancel-schedule')}
            footer={getFooterText()}
            error={reasonError}
            required
          >
            {(inputProps) => (
              <Select
                options={cancelScheduleReasons}
                renderOption={(option) => getText(bundle, option.label)}
                placeholder={getText(bundle, 'reason-select-placeholder')}
                {...inputProps}
                onChange={(option: Reason) => {
                  setReason(option);
                  setReasonError(false);
                }}
              />
            )}
          </InputWrapper>
        </Col>
        <Col gridGap="1rem">
          <Button
            variant={ButtonVariant.Primary}
            onClick={cancelSchedule}
            className="confirm-button"
          >
            {getText(bundle, 'button-modal-confirm')}
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={useNavigatorWithURLReady(urlToCurrentSchedulePage)}
            className="cancel-button"
          >
            {getText(bundle, 'button-modal-cancel')}
          </Button>
        </Col>
      </Col>
    </Col>
  );

  const spinner = (
    <Col alignItems="center">
      <Spinner
        size={SpinnerSize.Small}
        loadingText={getText(bundle, 'page-updating-message')}
        showText
      />
    </Col>
  );

  return (
    <>
      {updating && spinner}
      {!updating && confirmation}
    </>
  );
}
