import React from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Text } from '@amzn/stencil-react-components/text';
import { defaultTextColor } from 'src/config/palette';
import { AwsRumClient } from 'src/helpers/rum/awsRumClient';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
  > {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    // TBD: Log error using Client Side Error Tracker: https://code.amazon.com/packages/HvhClientSideErrorTracker/trees/mainline
    AwsRumClient.recordError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Col height="100vh">
          <PageContainer color={defaultTextColor} height="100%">
            <Col
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Text
                fontWeight="regular"
                fontSize="T300"
                textAlign="center"
              >
                Something went wrong
                {' '}
                <br />
                We are working to fix it
              </Text>
            </Col>
          </PageContainer>
        </Col>
      );
    }

    return this.props.children;
  }
}
