import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/button';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getText } from 'src/utility/common';

interface BackButtonProps {
  url: string;
  bundle: MessageBundle | undefined;
}

export function BackButton({ url, bundle }: BackButtonProps) {
  return (
    <Button
      icon={(
        <Row gridGap="1rem" alignItems="center">
          <IconArrowLeft title="iconArrowLeft" />
          <Text fontSize="T300">{getText(bundle, 'button-page-back')}</Text>
        </Row>
      )}
      size={ButtonSize.Default}
      variant={ButtonVariant.Tertiary}
      onClick={useNavigatorWithURLReady(url)}
      className="back-button"
    />
  );
}
