import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router-dom';
import {
  buildUrl,
  CANDIDATE_DASHBOARD_URL,
  CHECKLIST_URL,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import {
  getAvailableTimeSlotByAppId,
  getCurrentTimeSlotByAppId,
  getCurrentApplicationDetailsByAppId,
  rescheduleAppointment,
  RescheduleAppointmentRequest,
  cancelAppointment,
  CancelAppointmentRequest,
  PathToAppointmentType,
  AppointmentType,
  DEFAULT_APPOINTMENT_TYPE_PATH,
} from 'src/utility/appointment-data';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import { AppointmentListUncontrolled } from './card-uncontrolled';

interface AppointmentListProps {
  stage: Stage;
  bundle: MessageBundle;
}

export function AppointmentList({ stage, bundle }: AppointmentListProps) {
  const {
    applicationId,
    jobId,
    appointmentTypePath = DEFAULT_APPOINTMENT_TYPE_PATH,
  } = useParams<{
  applicationId: string;
  jobId: string;
  appointmentTypePath: keyof typeof PathToAppointmentType;
  }>();

  const appointmentType: AppointmentType = PathToAppointmentType[appointmentTypePath];

  const availableTimeSlotsAccessor = useCallback(() => getAvailableTimeSlotByAppId(applicationId, appointmentType), []) as any;

  const rescheduleAppointmentAccessor = useCallback(
    (request: RescheduleAppointmentRequest) => rescheduleAppointment(request),
    [],
  ) as any;

  const cancelAppointmentAccessor = useCallback(
    (request: CancelAppointmentRequest) => cancelAppointment(request),
    [],
  ) as any;

  const currentTimeSlotAccessor = useCallback(() => getCurrentTimeSlotByAppId(applicationId, appointmentType), []) as any;

  const getApplicationDetail = useCallback(() => getCurrentApplicationDetailsByAppId(applicationId), []) as any;

  const trackAppointmentUpdateClick = useCallback(() => {
    adobeAnalytics.addEventMetric(
      window,
      `successfully reschedule appointment ${appointmentType}`,
      applicationId,
      jobId,
    );
  }, [applicationId, jobId]);

  const checklistURL = buildUrl(CHECKLIST_URL, {
    stage,
    jobId,
    applicationId,
  });

  const candidateDashURL = buildUrl(CANDIDATE_DASHBOARD_URL, {
    stage,
    jobId,
    applicationId,
  });

  if (applicationId === undefined || jobId === undefined) {
    return null;
  }
  return (
    <Col maxWidth="672px">
      <AppointmentListUncontrolled
        applicationId={applicationId}
        currentTimeSlotAccessor={currentTimeSlotAccessor}
        availableTimeSlotsAccessor={availableTimeSlotsAccessor}
        rescheduleAppointmentAccessor={rescheduleAppointmentAccessor}
        cancelAppointmentAccessor={cancelAppointmentAccessor}
        getApplicationDetail={getApplicationDetail}
        trackAppointmentUpdateClick={trackAppointmentUpdateClick}
        checklistURL={checklistURL}
        candidateDashURL={candidateDashURL}
        bundle={bundle}
        stage={stage}
        appointmentType={appointmentType}
        jobId={jobId}
      />
    </Col>
  );
}
