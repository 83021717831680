import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Stage } from 'src/helpers/get-settings';
import * as adobeAnalytics from 'src/utility/adobe-analytics';
import {
  AvailableTimeSlots,
  RescheduleAppointmentRequest,
  RescheduleAppointmentResponse,
  CancelAppointmentRequest,
  CancelAppointmentResponse,
  TimeSlot,
  AppointmentType,
} from 'src/utility/appointment-data';
import { Application } from 'src/utility/schedule-data';
import { AppointmentListControlled } from './card-controlled';

interface AppointmentListUncontrolledProps {
  applicationId: string;
  availableTimeSlotsAccessor: () => Promise<AvailableTimeSlots | undefined>;
  rescheduleAppointmentAccessor: (
    request: RescheduleAppointmentRequest
  ) => Promise<RescheduleAppointmentResponse | undefined>;
  cancelAppointmentAccessor: (
    request: CancelAppointmentRequest
  ) => Promise<CancelAppointmentResponse | undefined>;
  currentTimeSlotAccessor: () => Promise<TimeSlot | undefined>;
  getApplicationDetail: () => Promise<Application | undefined>;
  trackAppointmentUpdateClick: () => void;
  checklistURL: string;
  candidateDashURL: string;
  bundle: MessageBundle | undefined;
  stage: Stage;
  appointmentType: AppointmentType;
  jobId: string;
}

export function AppointmentListUncontrolled({
  applicationId,
  availableTimeSlotsAccessor,
  rescheduleAppointmentAccessor,
  cancelAppointmentAccessor,
  checklistURL,
  candidateDashURL,
  currentTimeSlotAccessor,
  getApplicationDetail,
  trackAppointmentUpdateClick,
  bundle,
  stage,
  appointmentType,
  jobId,
}: AppointmentListUncontrolledProps) {
  const [timeSlots, setTimeSlots] = useState<AvailableTimeSlots | undefined>();
  const [curTimeSlot, setCurTimeSlot] = useState<TimeSlot | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string | number>();
  const [applicationState, setApplicationState] = useState<string>();
  const [isDragonspineEnabled, setisDragonspineEnabled] = useState<boolean>();

  const requestBackendData = useCallback(async () => {
    setLoading(true);
    try {
      const availableTimeSlots = await availableTimeSlotsAccessor();
      const currentTimeSlot = await currentTimeSlotAccessor();
      const currentApplication = await getApplicationDetail();

      if (!currentApplication?.errorCode) {
        setApplicationState(currentApplication?.applicationState);
      }
      if (currentTimeSlot?.errorCode) {
        setErrorCode(currentTimeSlot?.errorCode);
      }
      if (availableTimeSlots?.errorCode) {
        setError(true);
        await adobeAnalytics.addEventMetric(
          window,
          'Fail to get available time slots',
          applicationId,
          jobId,
          {
            error: availableTimeSlots?.errorMessage,
            appointmentType,
          },
        );
      } else {
        setTimeSlots(availableTimeSlots);
        setCurTimeSlot(currentTimeSlot);
        setisDragonspineEnabled(currentApplication?.isDragonspineEnabled);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    requestBackendData();
  }, []);

  return (
    <AppointmentListControlled
      applicationId={applicationId}
      timeSlots={timeSlots}
      curTimeSlot={curTimeSlot}
      rescheduleAppointmentAccessor={rescheduleAppointmentAccessor}
      cancelAppointmentAccessor={cancelAppointmentAccessor}
      applicationState={applicationState}
      isDragonspineEnabled={isDragonspineEnabled}
      trackAppointmentUpdateClick={trackAppointmentUpdateClick}
      checklistURL={checklistURL}
      candidateDashURL={candidateDashURL}
      error={error}
      errorCode={errorCode}
      loading={loading}
      bundle={bundle}
      stage={stage}
      appointmentType={appointmentType}
      jobId={jobId}
    />
  );
}
