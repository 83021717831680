import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { TimeSlot } from 'src/utility/appointment-data';
import { CancelConfirmationControlled } from './card-controlled';

interface RtwCancelConfirmationUncontrolledProps {
  getRtwDetail: () => Promise<TimeSlot | undefined>;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function CancelConfirmationUncontrolled({
  getRtwDetail,
  urlProps,
  bundle,
  stage,
}: RtwCancelConfirmationUncontrolledProps) {
  const [currentAppointmentDetails, setCurrentAppointmentDetails] = useState<
  TimeSlot | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const requestBackend = useCallback(async () => {
    setLoading(true);
    try {
      const details = await getRtwDetail();
      if (!details) {
        setError(true);
      } else {
        setCurrentAppointmentDetails(details);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    requestBackend();
  }, []);

  return (
    <CancelConfirmationControlled
      loading={loading}
      error={error}
      timeslot={currentAppointmentDetails}
      urlProps={urlProps}
      bundle={bundle}
      stage={stage}
    />
  );
}
