import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { getCurrentScheduleDetailsByAppId } from 'src/utility/schedule-data';
import { ScheduleCardUncontrolled } from './card-uncontrolled';

interface ScheduleCardProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function ScheduleCard({ bundle, stage }: ScheduleCardProps) {
  const { applicationId, jobId } = useParams<{
  applicationId: string;
  jobId: string;
  }>();

  const currentScheduleDetailsAccessor = useCallback(() => getCurrentScheduleDetailsByAppId(applicationId), []) as any;

  const urlProps = {
    applicationId,
    jobId,
  } as UrlProps;

  return (
    <ScheduleCardUncontrolled
      currentScheduleDetailsAccessor={currentScheduleDetailsAccessor}
      urlProps={urlProps}
      bundle={bundle}
      stage={stage}
    />
  );
}
