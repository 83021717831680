import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Card } from '@amzn/stencil-react-components/card';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import {
  DISPLAY_HOURLY_PAYMENT,
  MLS_REQUIRED_LANGUAGE,
} from 'src/config/feature-control';
import {
  signOnBonusBackgroundColor,
  surgePayBannerTextColor,
} from 'src/config/palette';
import './styles.scss';
import {
  buildUrl,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_CONFIRMATION_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getText } from 'src/utility/common';
import { getLanguageSupported } from 'src/utility/locale-data';
import { Schedule } from 'src/utility/schedule-data';

interface ScheduleSelectionProps {
  schedule: Schedule;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleSelection({
  schedule,
  urlProps,
  bundle,
  stage,
}: ScheduleSelectionProps) {
  const hourText = schedule.scheduleText.split('\n');

  const urlToScheduleUpdateConfirmationPage = buildUrl(
    SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_CONFIRMATION_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
      scheduleId: schedule.scheduleId,
    },
  );

  return (
    <Card
      padding="0rem"
      className="schedule-card"
      onClick={useNavigatorWithURLReady(urlToScheduleUpdateConfirmationPage)}
    >
      <Col
        className="details-col"
        gridGap="0.5rem"
        padding="1rem 0rem 1rem 1rem"
      >
        <Row className="schedule-hours">
          <Text fontSize="T200">
            <strong>
              {getText(bundle, 'schedule-card-field-hours')}
              :
            </strong>
            {' '}
            {schedule.hoursPerWeek}
            {' '}
            {getText(bundle, 'schedule-card-field-hours-unit')}
          </Text>
        </Row>

        <Row className="schedule-shift">
          <Text fontSize="T200">
            <strong>
              {getText(bundle, 'schedule-card-field-shift')}
              :
            </strong>
            &nbsp;
          </Text>
          <Text fontSize="T200">
            {hourText.map((text: string) => (
              <Row key={text}>{text}</Row>
            ))}
          </Text>
        </Row>

        <Row className="schedule-start-date">
          <Text fontSize="T200">
            <strong>
              {getText(bundle, 'schedule-card-field-start-date')}
              :
            </strong>
            {' '}
            {schedule.firstDayOnSiteL10N}
          </Text>
        </Row>

        {MLS_REQUIRED_LANGUAGE[stage] && schedule.requiredLanguage && (
          <Row className="schedule-required-language">
            <Text fontSize="T200">
              <strong>
                {getText(bundle, 'schedule-card-field-required-language')}
                :
              </strong>
              {' '}
              {getLanguageSupported(schedule.requiredLanguage, bundle)}
            </Text>
          </Row>
        )}
      </Col>
      <Col className="bonus-col" justifyContent="space-between">
        <Row justifyContent="end" className="schedule-sign-on-bonus">
          <Col
            backgroundColor={signOnBonusBackgroundColor}
            padding="0rem 0.5rem 0rem 0.5rem"
          >
            {schedule.signOnBonusL10N
              && schedule.signOnBonusL10N !== 'null' && (
                <Text
                  color={surgePayBannerTextColor}
                  fontSize="T200"
                  wordBreak="keep-all"
                >
                  {getText(bundle, 'schedule-card-field-bonus')}
                  {' '}
                  {schedule.signOnBonusL10N}
                </Text>
            )}
          </Col>
        </Row>
        <Row
          padding="1rem"
          justifyContent="end"
          alignItems="baseline"
          className="schedule-total-pay-rate"
        >
          <Text fontSize="T400">
            <strong>
              {DISPLAY_HOURLY_PAYMENT[stage]
                ? schedule.totalPayRateL10N
                : schedule.monthlyBasePayL10N}
            </strong>
          </Text>
          <Text fontSize="T200">
            {getText(
              bundle,
              DISPLAY_HOURLY_PAYMENT[stage]
                ? 'schedule-card-field-pay-rate-unit'
                : 'schedule-card-field-pay-rate-unit-month',
            )}
          </Text>
        </Row>
      </Col>
    </Card>
  );
}
