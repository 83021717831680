import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Card } from '@amzn/stencil-react-components/card';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { appointmentBannerBackgroundColor } from 'src/config/palette';
import { Stage } from 'src/helpers/get-settings';
import { TimeSlot, validNHEPooling } from 'src/utility/appointment-data';
import { getFormattedText, getText } from 'src/utility/common';
import { convertToYYYYMMDDHHmm } from 'src/utility/time';
import { getAppointmentDetails } from './appointment-details-helper';
import calendar from './calendar.png';
import './styles.scss';

interface AppointmentBannerProps {
  timeSlot?: TimeSlot;
  applicationState?: string;
  languages?: string;
  bundle?: MessageBundle;
  stage: Stage;
}

export function AppointmentBanner({
  timeSlot,
  applicationState,
  languages,
  bundle,
  stage,
}: AppointmentBannerProps) {
  const calendarAppointment = validNHEPooling(applicationState, stage) && !timeSlot?.startDate ? null : (
    <Col justifyContent="center">
      <img
        className="calendar"
        src={calendar}
        alt="Calendar"
        width="80px"
        aria-hidden="true"
      />
      <Text className="calendar-day" fontSize="35px">
        {getFormattedText(bundle, 'date-format-day', {
          dataParam: convertToYYYYMMDDHHmm(
            `${timeSlot?.startDate} ${timeSlot?.startTime}`,
          ),
        })}
      </Text>
      <Text className="calendar-month" fontSize="12px">
        {getFormattedText(bundle, 'date-format-month-short', {
          dataParam: convertToYYYYMMDDHHmm(
            `${timeSlot?.startDate} ${timeSlot?.startTime}`,
          ),
        })}
      </Text>
    </Col>
  );

  const hasNoAppointmentScheduled = validNHEPooling(applicationState, stage) && !timeSlot?.startDate;

  return (
    <Card justifyContent="center" padding="0">
      <Col width="100%">
        <Row
          backgroundColor={appointmentBannerBackgroundColor}
          justifyContent="center"
          alignItems="center"
          gridGap="1rem"
          padding="1rem"
        >
          {calendarAppointment}
          <Col>
            <Text
              className="appointment-details"
              fontSize="T250"
              lineHeight="2"
            >
              {getText(
                bundle,
                hasNoAppointmentScheduled
                  ? 'banner-need-appointment'
                  : 'banner-current-appointment',
              )}
              <br />
            </Text>
            {!hasNoAppointmentScheduled
              && timeSlot
              && getAppointmentDetails(timeSlot, bundle, stage, languages)}
          </Col>
        </Row>
      </Col>
    </Card>
  );
}
