import React, { useEffect } from 'react';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import HR2021Theme from '@amzn/stencil-react-theme-default';
import {
  useLocation, BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { SWRConfig } from 'swr';
import { ErrorBoundary } from './components/error-boundary';
import {
  APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL,
  SELF_SERVICE_CANCEL_COMPLETE_URL,
  SELF_SERVICE_CANCEL_CONFIRMATION_URL,
  SELF_SERVICE_UPDATE_FAILURE_URL,
  SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL,
  SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_COMPLETE_URL,
  SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_CONFIRMATION_URL,
  SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_COMPLETE_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_CONFIRMATION_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL,
} from './config/urls';
import { fetcher } from './helpers/fetcher';
import { withStage } from './hoc/with-stage';
import { AppointmentSelfServiceRoute } from './routes/appointment-self-service';
import { NoMatchRoute } from './routes/no-match';
import { RtwSelfServiceCancelRtwCompleteRoute } from './routes/rtw-self-service/cancel-complete';
import { RtwSelfServiceCancelRtwConfirmationRoute } from './routes/rtw-self-service/rtw-cancel-confirmation';
import { RtwSelfServiceUpdateRtwFailureRoute } from './routes/rtw-self-service/update-failure';
import { ScheduleSelfServiceAvailableScheduleRoute } from './routes/schedule-self-service/available-schedule';
import { ScheduleSelfServiceCancelScheduleCompleteRoute } from './routes/schedule-self-service/cancel-complete';
import { ScheduleSelfServiceCurrentScheduleRoute } from './routes/schedule-self-service/current-schedule';
import { ScheduleSelfServiceCancelScheduleConfirmationRoute } from './routes/schedule-self-service/schedule-cancel-confirmation';
import { ScheduleSelfServiceUpdateScheduleConfirmationRoute } from './routes/schedule-self-service/schedule-update-confirmation';
import { ScheduleSelfServiceUpdateScheduleCompleteRoute } from './routes/schedule-self-service/update-complete';
import { ScheduleSelfServiceUpdateScheduleFailureRoute } from './routes/schedule-self-service/update-failure';

function ScrollToTop(): any {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// TODO: CSRF authentication

const AppointmentSelfServiceRouteWithStage = withStage(
  AppointmentSelfServiceRoute,
);
const ScheduleSelfServiceAvailableScheduleRouteWithStage = withStage(
  ScheduleSelfServiceAvailableScheduleRoute,
);
const ScheduleSelfServiceCurrentScheduleRouteWithStage = withStage(
  ScheduleSelfServiceCurrentScheduleRoute,
);
const ScheduleSelfServiceUpdateScheduleConfirmationRouteWithStage = withStage(
  ScheduleSelfServiceUpdateScheduleConfirmationRoute,
);
const ScheduleSelfServiceCancelScheduleConfirmationRouteWithStage = withStage(
  ScheduleSelfServiceCancelScheduleConfirmationRoute,
);
const ScheduleSelfServiceUpdateScheduleCompleteRouteWithStage = withStage(
  ScheduleSelfServiceUpdateScheduleCompleteRoute,
);
const ScheduleSelfServiceUpdateScheduleFailureRouteWithStage = withStage(
  ScheduleSelfServiceUpdateScheduleFailureRoute,
);
const ScheduleSelfServiceCancelScheduleCompleteRouteWithStage = withStage(
  ScheduleSelfServiceCancelScheduleCompleteRoute,
);
const RtwSelfServiceCancelRtwConfirmationRouteWithStage = withStage(
  RtwSelfServiceCancelRtwConfirmationRoute,
);
const RtwSelfServiceCancelRtwCompleteRouteWithStage = withStage(
  RtwSelfServiceCancelRtwCompleteRoute,
);

const RtwSelfServiceUpdateRtwFailureRouteWithStage = withStage(
  RtwSelfServiceUpdateRtwFailureRoute,
);

const NoMatchRouteWithStage = withStage(NoMatchRoute);

function App() {
  return (
    <StencilProvider theme={HR2021Theme}>
      <ErrorBoundary>
        <div className="app">
          <main>
            <SWRConfig
              value={{
                fetcher,
              }}
            >
              <BrowserRouter>
                <ScrollToTop />
                <Switch>
                  <Route path={APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL}>
                    <AppointmentSelfServiceRouteWithStage />
                  </Route>
                  <Route path={SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL}>
                    <ScheduleSelfServiceAvailableScheduleRouteWithStage />
                  </Route>
                  <Route path={SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL}>
                    <ScheduleSelfServiceCurrentScheduleRouteWithStage />
                  </Route>
                  <Route
                    path={
                      SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_CONFIRMATION_URL
                    }
                  >
                    <ScheduleSelfServiceUpdateScheduleConfirmationRouteWithStage />
                  </Route>
                  <Route
                    path={
                      SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_CONFIRMATION_URL
                    }
                  >
                    <ScheduleSelfServiceCancelScheduleConfirmationRouteWithStage />
                  </Route>
                  <Route
                    path={SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_COMPLETE_URL}
                  >
                    <ScheduleSelfServiceUpdateScheduleCompleteRouteWithStage />
                  </Route>
                  <Route
                    path={SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_COMPLETE_URL}
                  >
                    <ScheduleSelfServiceCancelScheduleCompleteRouteWithStage />
                  </Route>
                  <Route
                    path={SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL}
                  >
                    <ScheduleSelfServiceUpdateScheduleFailureRouteWithStage />
                  </Route>
                  <Route path={SELF_SERVICE_CANCEL_CONFIRMATION_URL}>
                    <RtwSelfServiceCancelRtwConfirmationRouteWithStage />
                  </Route>
                  <Route path={SELF_SERVICE_CANCEL_COMPLETE_URL}>
                    <RtwSelfServiceCancelRtwCompleteRouteWithStage />
                  </Route>
                  <Route path={SELF_SERVICE_UPDATE_FAILURE_URL}>
                    <RtwSelfServiceUpdateRtwFailureRouteWithStage />
                  </Route>
                  <Route path="*">
                    <NoMatchRouteWithStage />
                  </Route>
                </Switch>
              </BrowserRouter>
            </SWRConfig>
          </main>
        </div>
      </ErrorBoundary>
    </StencilProvider>
  );
}

export default App;
