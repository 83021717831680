import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { useParams } from 'react-router-dom';
import { ScheduleUpdateConfirmation } from 'src/components/schedule-update-confirmation';
import { pageBackgroundColor } from 'src/config/palette';
import { Stage } from 'src/helpers/get-settings';
import { AwsRumClient } from 'src/helpers/rum/awsRumClient';
import * as adobeAnalytics from '../../../utility/adobe-analytics';

interface ScheduleSelfServiceUpdateScheduleConfirmationRouteProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function ScheduleSelfServiceUpdateScheduleConfirmationRoute({
  bundle,
  stage,
}: ScheduleSelfServiceUpdateScheduleConfirmationRouteProps) {
  const { applicationId, jobId } = useParams<{
  applicationId: string;
  jobId: string;
  }>();

  adobeAnalytics.addPageLoadMetric(
    window,
    'update-shift-confirmation',
    applicationId,
    jobId,
  );
  AwsRumClient.recordPageView(
    'css-update-shift-confirmation',
    window.location.href,
    applicationId,
    jobId,
  );

  return (
    <PageContainer
      backgroundColor={pageBackgroundColor}
      alignItems="center"
      paddingTop={32}
      paddingHorizontal={16}
      paddingBottom={64}
    >
      <Col alignItems="center">
        <Col width="100%" maxWidth="672px">
          <ScheduleUpdateConfirmation bundle={bundle} stage={stage} />
        </Col>
      </Col>
    </PageContainer>
  );
}
