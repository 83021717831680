import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Chip, ChipSize } from '@amzn/stencil-react-components/chip';
import { IconDesktop, IconPerson } from '@amzn/stencil-react-components/icons';
import { Row, Spacer } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { MLS_REQUIRED_LANGUAGE } from 'src/config/feature-control';
import { Stage } from 'src/helpers/get-settings';
import { SimplifiedTimeSlot, TimeSlot } from 'src/utility/appointment-data';
import { getFormattedText, getText } from 'src/utility/common';
import { convertToYYYYMMDDHHmm } from 'src/utility/time';

export enum LocationType {
  PHYSICAL = 'physical',
  VIRTUAL_CHIME = 'virtual_chime',
  VIRTUAL_LINK = 'virtual_link',
}

export const isPhysicalAppointment = (locationType?: string) => !locationType || locationType?.toLowerCase() === LocationType.PHYSICAL;

const getAppointmentLabel = (
  bundle?: MessageBundle,
  locationType?: string,
  size?: ChipSize,
) => (isPhysicalAppointment(locationType) ? (
  <Chip icon={<IconPerson />} size={size || ChipSize.Small}>
    {getText(bundle, 'in-person-appointment-label')}
  </Chip>
) : (
  <Chip icon={<IconDesktop />} size={size || ChipSize.Small}>
    {getText(bundle, 'virtual-appointment-label')}
  </Chip>
));

const getSpokenLanguage = (
  stage: Stage,
  bundle?: MessageBundle,
  languages?: string,
) => MLS_REQUIRED_LANGUAGE[stage]
  && languages && (
    <Text
      fontSize="T200"
      lineHeight="2"
      className="appointment-spoken-language"
    >
        {`${getText(bundle, 'banner-required-language')}: ${languages}`}
    </Text>
);

const getAppointmentAddress = (appointment: TimeSlot | SimplifiedTimeSlot) => (
  <Text fontSize="T200" lineHeight="2" className="appointment-address">
    {isPhysicalAppointment(appointment.locationType) ? appointment.displayReadyLocation : ''}
  </Text>
);

const getAppointmentStartDate = (
  timeSlot: TimeSlot | SimplifiedTimeSlot,
  bundle?: MessageBundle,
  fontSize = 'T300',
) => (
  <Text
    className="appointment-details"
    fontSize={fontSize}
    fontWeight="bold"
    lineHeight="2"
  >
    {timeSlot.displayReadyTimeSlot
      ? timeSlot.displayReadyTimeSlot
      : getFormattedText(bundle, 'banner-current-appointment-details', {
        startDataParam: convertToYYYYMMDDHHmm(
          `${timeSlot.startDate} ${timeSlot.startTime}`,
        ),
        endDataParam: convertToYYYYMMDDHHmm(
          `${timeSlot.endDate} ${timeSlot.endTime}`,
        ),
      })}
  </Text>
);

export const getAppointmentDetails = (
  timeSlot: TimeSlot | SimplifiedTimeSlot,
  bundle?: MessageBundle,
  stage?: Stage,
  languages?: string,
  startDateFontSize?: string,
) => (
  <>
    {getAppointmentStartDate(timeSlot, bundle, startDateFontSize)}
    {timeSlot.displayReadyLocation && getAppointmentAddress(timeSlot)}
    <Spacer height={4} />
    <Row alignItems="center">
      {getAppointmentLabel(bundle, timeSlot.locationType)}
      <Spacer width={10} />
      {stage && getSpokenLanguage(stage, bundle, languages)}
    </Row>
  </>
);
