import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Card } from '@amzn/stencil-react-components/card';
import { Flyout } from '@amzn/stencil-react-components/flyout';
import {
  DetailedRadio,
  FormWrapper,
} from '@amzn/stencil-react-components/form';
import { IconCross, IconRefresh } from '@amzn/stencil-react-components/icons';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import './styles.scss';
import { UrlProps } from 'src/config/urls';
import { getText } from 'src/utility/common';
import { sorterState } from 'src/utility/schedule-data';
import * as adobeAnalytics from '../../utility/adobe-analytics';

interface SortFlyoutProps {
  isSortFlyoutOpen: boolean;
  closeSortFlyout: () => void;
  sorterStatus: any;
  setSorterStatus: any;
  applyButtonClick: any;
  setApplyButtonClick: any;
  bundle: MessageBundle | undefined;
  urlProps: UrlProps;
}

export function SortFlyout({
  isSortFlyoutOpen,
  closeSortFlyout,
  sorterStatus,
  setSorterStatus,
  applyButtonClick,
  setApplyButtonClick,
  bundle,
  urlProps,
}: SortFlyoutProps) {
  const reset = () => {
    setSorterStatus(sorterState[0].titleText);
  };

  const closeAndApply = () => {
    closeSortFlyout();
    setApplyButtonClick(!applyButtonClick);
    adobeAnalytics.addEventMetric(
      window,
      'apply sorting on self service page',
      urlProps.applicationId as string,
      urlProps.jobId as string,
    );
  };

  return (
    <Flyout isOpen={isSortFlyoutOpen} close={closeSortFlyout}>
      <Col className="flyout" justifyContent="space-between">
        <Col>
          <Card justifyContent="space-between" alignItems="center">
            <Button
              icon={<IconRefresh title="iconRefresh" />}
              variant={ButtonVariant.Tertiary}
              onClick={reset}
              className="reset-sort-button-one"
            />
            <Text fontSize="T400">Sorter</Text>
            <Button
              icon={<IconCross title="iconRefresh" />}
              variant={ButtonVariant.Tertiary}
              onClick={closeSortFlyout}
              className="close-sort-button"
            />
          </Card>
          <Col
            overflow="auto"
            padding="1rem"
            gridGap="1rem"
            className="sort-fieldset"
          >
            <fieldset>
              <FormWrapper className="sort-fieldset-wrapper">
                {sorterState.map((option) => (
                  <DetailedRadio
                    key={option.titleText}
                    titleText={getText(bundle, option.label)}
                    value={option.value}
                    name="output-format"
                    checked={option.titleText === sorterStatus}
                    onChange={() => setSorterStatus(option.titleText)}
                  />
                ))}
              </FormWrapper>
            </fieldset>
          </Col>
        </Col>

        <Card justifyContent="space-around" alignItems="center">
          <Row gridGap="1rem">
            <Button onClick={reset} className="reset-sort-button-two">
              {getText(bundle, 'button-reset')}
            </Button>
            <Button
              onClick={closeAndApply}
              variant={ButtonVariant.Primary}
              className="apply-sort-button"
            >
              {getText(bundle, 'button-apply')}
            </Button>
          </Row>
        </Card>
      </Col>
    </Flyout>
  );
}
