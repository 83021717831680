import React, { useEffect } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router-dom';
import { AppointmentCard } from 'src/components/appointment-card';
import { AppointmentList } from 'src/components/appointment-list';
import { pageBackgroundColor } from 'src/config/palette';
import {
  APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL,
  buildUrl,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { AwsRumClient } from 'src/helpers/rum/awsRumClient';
import {
  DEFAULT_APPOINTMENT_TYPE_PATH,
  PathToAppointmentType,
} from 'src/utility/appointment-data';
import * as adobeAnalytics from '../../utility/adobe-analytics';

interface AppointmentSelfServiceRouteProps {
  stage: Stage;
  bundle: MessageBundle;
}

export function AppointmentSelfServiceRoute({
  stage,
  bundle,
}: AppointmentSelfServiceRouteProps) {
  const { applicationId, jobId, appointmentTypePath } = useParams<{
  applicationId: string;
  jobId: string;
  appointmentTypePath?: keyof typeof PathToAppointmentType;
  }>();

  // TODO: Remove this temporary solution all instances of the old NHE
  //  selfservice url are updated from "selfservice/appointment/" to "selfservice/appointment/new-hire-event"
  const urlToDefaultAppointmentTypePath = buildUrl(
    APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL.replace('?', ''),
    {
      applicationId,
      jobId,
      appointmentTypePath: DEFAULT_APPOINTMENT_TYPE_PATH,
    },
  );

  // if appointmentTypePath is empty, redirect to the default appointment type URL
  // this is to redirect legacy NHE links to the new NHE URL
  if (!appointmentTypePath) {
    window.location.assign(urlToDefaultAppointmentTypePath);
    return null;
  }

  useEffect(() => {
    adobeAnalytics.addPageLoadMetric(
      window,
      `candidate-self-service-appointment-rescheduling-${appointmentTypePath}`,
      applicationId,
      jobId,
    );
    AwsRumClient.recordPageView(
      'css-appt-reschedule',
      window.location.href,
      applicationId,
      jobId,
    );
  }, [applicationId, jobId]);

  return (
    <Col
      backgroundColor={pageBackgroundColor}
      dataTestId="appointment-self-service-route"
    >
      <Col>
        <AppointmentCard stage={stage} bundle={bundle} />
      </Col>
      <Col alignItems="center">
        <AppointmentList stage={stage} bundle={bundle} />
      </Col>
    </Col>
  );
}
