import React from 'react';
import { buildUrl, PAGE_NOT_FOUND_URL } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { AwsRumClient } from 'src/helpers/rum/awsRumClient';

interface NoMatchProps {
  stage: Stage;
}

export function NoMatchRoute({ stage }: NoMatchProps) {
  AwsRumClient.recordPageView('css-page-not-found', window.location.href);
  window.location.href = buildUrl(PAGE_NOT_FOUND_URL, {
    stage,
  });
  return <div />;
}
