import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { buildUrl, CHECKLIST_URL } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import {
  getCurrentTimeSlotByAppId,
  getCurrentApplicationDetailsByAppId,
  PathToAppointmentType,
  DEFAULT_APPOINTMENT_TYPE_PATH,
} from 'src/utility/appointment-data';
import { AppointmentCardUncontrolled } from './card-uncontrolled';

interface AppointmentCardProps {
  stage: Stage;
  bundle: MessageBundle;
}

export function AppointmentCard({ stage, bundle }: AppointmentCardProps) {
  const {
    applicationId,
    jobId,
    appointmentTypePath = DEFAULT_APPOINTMENT_TYPE_PATH,
  } = useParams<{
  applicationId: string;
  jobId: string;
  appointmentTypePath: keyof typeof PathToAppointmentType;
  }>();

  const appointmentType = PathToAppointmentType[appointmentTypePath];

  const currentTimeSlotAccessor = useCallback(() => getCurrentTimeSlotByAppId(applicationId, appointmentType), []) as any;

  const getApplicationDetail = useCallback(() => getCurrentApplicationDetailsByAppId(applicationId), []) as any;

  const checklistURL = buildUrl(CHECKLIST_URL, {
    stage,
    jobId,
    applicationId,
  });

  return (
    <AppointmentCardUncontrolled
      data-testid="appointment-card"
      appointmentType={appointmentType}
      currentTimeSlotAccessor={currentTimeSlotAccessor}
      getApplicationDetail={getApplicationDetail}
      checklistURL={checklistURL}
      bundle={bundle}
      stage={stage}
    />
  );
}
