import React, { useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/button';
import { Select } from '@amzn/stencil-react-components/form';
import { IconArrowLeft } from '@amzn/stencil-react-components/icons';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import './styles.scss';
import { MLS_REQUIRED_LANGUAGE } from 'src/config/feature-control';
import {
  buildUrl,
  SELF_SERVICE_CANCEL_CONFIRMATION_URL,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import * as adobeAnalytics from 'src/utility/adobe-analytics';
import {
  AppointmentType,
  AppointmentTypePath,
  AvailableTimeSlots,
  CancelAppointmentRequest,
  CancelAppointmentResponse,
  organizeTimeSlots,
  RescheduleAppointmentRequest,
  RescheduleAppointmentResponse,
  TimeSlot,
  SimplifiedTimeSlot,
} from 'src/utility/appointment-data';
import {
  delay,
  getFormattedText,
  getText,
  validEUStage,
} from 'src/utility/common';
import { getLanguageSupported, getLocale } from 'src/utility/locale-data';
import { compareLeftBounds, convertToYYYYMMDDHHmm } from 'src/utility/time';
import { getAppointmentDetails } from '../appointment-card/appointment-details-helper';
import { H2 } from '../typography';
import { AppointmentConfirmation } from './appointment-confirmation';
import { Expander } from './expander';

interface AppointmentSlotsProps {
  applicationId: string;
  timeSlots: AvailableTimeSlots;
  curTimeSlot?: TimeSlot;
  rescheduleAppointmentAccessor: (
    request: RescheduleAppointmentRequest
  ) => Promise<RescheduleAppointmentResponse | undefined>;
  cancelAppointmentAccessor: (
    request: CancelAppointmentRequest
  ) => Promise<CancelAppointmentResponse | undefined>;
  isDragonspineEnabled?: boolean;
  trackAppointmentUpdateClick: () => void;
  checklistURL: string;
  candidateDashURL: string;
  bundle: MessageBundle | undefined;
  stage: Stage;
  appointmentType: AppointmentType;
  jobId: string;
}

export function AppointmentSlots({
  applicationId,
  timeSlots,
  curTimeSlot,
  rescheduleAppointmentAccessor,
  cancelAppointmentAccessor,
  isDragonspineEnabled,
  checklistURL,
  candidateDashURL,
  trackAppointmentUpdateClick,
  bundle,
  stage,
  appointmentType,
  jobId,
}: AppointmentSlotsProps) {
  const [map, availableLocales] = organizeTimeSlots(
    timeSlots.timeSlots,
    curTimeSlot,
  );

  const dates = Array.from(map.keys()).sort();
  const curDate = convertToYYYYMMDDHHmm(
    `${curTimeSlot?.startDate} ${curTimeSlot?.startTime}`,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SimplifiedTimeSlot>();
  const [selectedStartDate, setSelectedStartDate] = useState<string>(dates[0]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(getLocale());

  function getAvailableRange(date: any, language: any) {
    return Array.from(map.get(date)?.keys() || '')
      .filter((range) => map
        .get(date)
        ?.get(range)
        ?.some((timeSlot) => timeSlot.locales.includes(language)))
      .sort((a, b) => compareLeftBounds(a, b));
  }
  const [availableRange, setAvailableRange] = useState<Array<string>>(
    getAvailableRange(dates[0], getLocale()),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateConfirmationOpen, setIsUpdateConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isLoadingScreenOpen, setIsLoadingScreenOpen] = useState(false);
  const [isProgressCompletedViewOpen, setIsProgressCompletedViewOpen] = useState(false);
  const [isCancelSuccessViewOpen, setIsCancelSuccessViewOpen] = useState(false);
  const [isProgressFailedViewOpen, setIsProgressFailedViewOpen] = useState(false);

  const urlToCancelRtwAppointment = buildUrl(
    SELF_SERVICE_CANCEL_CONFIRMATION_URL,
    {
      applicationId,
      jobId,
      appointmentTypePath: AppointmentTypePath.RIGHT_TO_WORK,
    },
  );

  const onChangeDate = async (date: any) => {
    setSelectedStartDate(date);
    setLoading(true);
    setAvailableRange(getAvailableRange(date, selectedLanguage));
    await delay(300);
    setLoading(false);
  };

  const onChangeLanguage = async (language: any) => {
    setSelectedLanguage(language);
    setLoading(true);
    setAvailableRange(getAvailableRange(selectedStartDate, language));
    await delay(300);
    setLoading(false);
  };

  const closeAllContents = () => {
    setIsUpdateConfirmationOpen(false);
    setIsLoadingScreenOpen(false);
    setIsProgressCompletedViewOpen(false);
    setIsCancelSuccessViewOpen(false);
    setIsProgressFailedViewOpen(false);
    setIsCancelConfirmationOpen(false);
  };

  const openModal = (slot: SimplifiedTimeSlot) => {
    setSelectedTimeSlot(slot);
    setIsModalOpen(true);
    setIsUpdateConfirmationOpen(true);
  };

  const openCancelModal = () => {
    setIsModalOpen(true);
    setIsCancelConfirmationOpen(true);
  };

  const closeModal = () => {
    closeAllContents();
    setIsModalOpen(false);
  };

  const displayLoadingScreen = () => {
    closeAllContents();
    setIsLoadingScreenOpen(true);
  };

  const displayProgressCompletedView = () => {
    closeAllContents();
    setIsProgressCompletedViewOpen(true);
  };

  const displayCancelSuccessView = () => {
    closeAllContents();
    setIsCancelSuccessViewOpen(true);
  };

  const displayProgressFailedView = () => {
    closeAllContents();
    setIsProgressFailedViewOpen(true);
  };

  const onClickUpdateAppointment = async () => {
    displayLoadingScreen();
    try {
      if (!selectedTimeSlot) {
        displayProgressFailedView();
      } else {
        const request = {
          applicationId,
          appointmentType,
          venueId: selectedTimeSlot.venueId,
          timeSlotId: selectedTimeSlot.timeSlotId,
          userAlias: 'CandidateSelfService',
          locale: getLocale(),
        };
        const response = await rescheduleAppointmentAccessor(request);

        if (response?.errorCode === 500) {
          displayProgressFailedView();
          await adobeAnalytics.addEventMetric(
            window,
            'Failed to update appointment',
            applicationId,
            jobId,
            {
              error: response?.errorMessage,
              appointmentType,
            },
          );
        } else {
          trackAppointmentUpdateClick();
          displayProgressCompletedView();
        }
      }
    } catch (err) {
      displayProgressFailedView();
    }
  };

  const onClickCancelAppointment = async () => {
    displayLoadingScreen();
    try {
      if (!curTimeSlot) {
        displayProgressFailedView();
      } else {
        const request = {
          applicationId,
          appointmentType,
          appointmentStatus: 'RELEASED',
          userAlias: 'CandidateSelfService',
          timeSlotId: curTimeSlot.timeSlotId,
          requestingService: 'CandidateSelfService',
        };
        const response = await cancelAppointmentAccessor(request);

        if (response?.errorCode === 500) {
          displayProgressFailedView();
        } else {
          trackAppointmentUpdateClick();
          displayCancelSuccessView();
        }
      }
    } catch (err) {
      displayProgressFailedView();
    }
  };

  const schedulingHeading = !curTimeSlot || curTimeSlot.errorCode
    ? getText(bundle, 'heading-modal-appointment-schedule')
    : getText(bundle, 'heading-modal-appointment-reschedule');

  const schedulingSubheading = !curTimeSlot || curTimeSlot.errorCode
    ? getText(bundle, 'subheading-modal-appointment-schedule')
    : getText(bundle, 'subheading-modal-appointment-reschedule');

  const languageSupported = getLanguageSupported(selectedLanguage, bundle);

  const rescheduleConfirmation = (
    <ModalContent
      dataTestId="reschedule-confirmation-modal"
      titleText={schedulingHeading}
      buttons={[
        <Col key="confirmed" width="100%">
          <Row width="100%" gridGap="1rem">
            <Col width="100%">
              <Button onClick={closeModal} className="cancel-button">
                {getText(bundle, 'button-modal-cancel')}
              </Button>
            </Col>
            <Col width="100%">
              <Button
                onClick={onClickUpdateAppointment}
                variant={ButtonVariant.Primary}
                className="confirm-button"
              >
                {getText(bundle, 'button-modal-confirm')}
              </Button>
            </Col>
          </Row>
        </Col>,
      ]}
    >
      <Text className="confirmation-question">
        {schedulingSubheading}
        :
      </Text>
      <Spacer height={10} />
      {selectedTimeSlot
        && getAppointmentDetails(
          selectedTimeSlot,
          bundle,
          stage,
          languageSupported,
        )}
    </ModalContent>
  );

  const cancelConfirmation = (
    <ModalContent
      titleText={getText(bundle, 'heading-modal-appointment-cancel')}
      buttons={[
        <Col key="confirmed" width="100%">
          <Row width="100%" gridGap="1rem">
            <Col width="100%">
              <Button onClick={closeModal} className="cancel-button">
                {getText(bundle, 'button-modal-cancel')}
              </Button>
            </Col>
            <Col width="100%">
              <Button
                onClick={onClickCancelAppointment}
                variant={ButtonVariant.Primary}
                className="confirm-button"
              >
                {getText(bundle, 'button-modal-confirm')}
              </Button>
            </Col>
          </Row>
        </Col>,
      ]}
    >
      <Text className="confirmation-question">
        {getFormattedText(bundle, 'subheading-modal-appointment-cancel', {
          dataParam: curDate,
        })}
        ?
      </Text>
    </ModalContent>
  );

  const loadingScreen = (
    <ModalContent titleText={getText(bundle, 'modal-loading-message')}>
      <Col padding="1rem" alignItems="center" className="loading">
        <Spinner size={SpinnerSize.Small} showText={false} />
      </Col>
    </ModalContent>
  );

  const nextStep = !isDragonspineEnabled ? candidateDashURL : checklistURL;

  const progressCompletedView = (
    <ModalContent
      titleText=" "
      buttons={[
        <Col key="done" width="600px">
          <Row width="100%" gridGap="1rem">
            <Col width="100%">
              <Button
                onClick={useNavigatorWithURLReady(nextStep)}
                className="ash-redirect-button"
              >
                {getText(bundle, 'button-continue-next-step')}
              </Button>
            </Col>
          </Row>
        </Col>,
      ]}
    >
      <Col width="100%" className="new-appointment-details">
        <AppointmentConfirmation
          stage={stage}
          languages={languageSupported}
          timeSlot={selectedTimeSlot}
          bundle={bundle}
        />
      </Col>
    </ModalContent>
  );

  const cancelSuccessView = (
    <ModalContent
      titleText={getText(bundle, 'modal-appointment-cancel-success')}
    >
      <Col alignItems="center" className="failed-to-reschedule">
        <Button
          variant={ButtonVariant.Primary}
          onClick={useNavigatorWithURLReady(nextStep)}
          className="ash-redirect-button"
        >
          {getText(bundle, 'button-continue-next-step')}
        </Button>
      </Col>
    </ModalContent>
  );

  const progressFailedView = (
    <ModalContent
      titleText={getText(bundle, 'modal-error-appointment-update-failed')}
    >
      <Col alignItems="center" className="failed-to-reschedule">
        <Button
          variant={ButtonVariant.Primary}
          onClick={closeModal}
          className="cancel-button"
        >
          {getText(bundle, 'button-modal-close')}
        </Button>
      </Col>
    </ModalContent>
  );

  const modal = (
    <Modal
      isOpen={isModalOpen}
      close={closeModal}
      shouldCloseOnClickOutside={false}
    >
      {isModalOpen && isUpdateConfirmationOpen && rescheduleConfirmation}
      {isModalOpen && isCancelConfirmationOpen && cancelConfirmation}
      {isLoadingScreenOpen && loadingScreen}
      {isProgressFailedViewOpen && progressFailedView}
      {isProgressCompletedViewOpen && progressCompletedView}
      {isCancelSuccessViewOpen && cancelSuccessView}
    </Modal>
  );

  const expanders = availableRange.length === 0 ? (
    <MessageBanner type={MessageBannerType.Informational}>
      {MLS_REQUIRED_LANGUAGE[stage]
          && getFormattedText(
            bundle,
            'banner-error-no-appointment-available-current-language',
            {
              languageName: getLanguageSupported(
                selectedLanguage,
                bundle,
                true,
              ),
            },
          )}
      {!MLS_REQUIRED_LANGUAGE[stage]
          && getText(bundle, 'banner-error-no-appointment-available')}
    </MessageBanner>
  ) : (
    <Col gridGap="1rem">
      {availableRange.map((item) => (
        <Expander
          key={item}
          timeRange={item}
          loading={loading}
          onClickButton={openModal}
          slots={map
            .get(selectedStartDate)
            ?.get(item)
            ?.filter((e) => e.locales.includes(selectedLanguage))
            .sort()}
          bundle={bundle}
        />
      ))}
    </Col>
  );

  const cancelAppointment = validEUStage(stage) && curTimeSlot && !curTimeSlot.errorCode ? (
    <Col width="100%">
      <Button
        onClick={
            appointmentType === AppointmentType.RIGHT_TO_WORK
              ? () => window.location.assign(urlToCancelRtwAppointment)
              : openCancelModal
          }
        variant={ButtonVariant.Primary}
        className="cancel-button"
      >
        {getText(bundle, 'button-modal-cancel-appointment')}
      </Button>
    </Col>
  ) : null;

  const appointmentScheduling = !curTimeSlot || curTimeSlot.errorCode ? (
    <H2>{getText(bundle, 'heading-page-schedule-appointment')}</H2>
  ) : (
    <H2>{getText(bundle, 'heading-page-reschedule-appointment')}</H2>
  );

  return (
    <Col gridGap="2rem" padding="1rem">
      <Col gridGap="1rem">
        <Row gridGap="0.5rem" alignItems="center">
          <Col>
            <Button
              icon={<IconArrowLeft title="iconArrowLeft" />}
              size={ButtonSize.Default}
              variant={ButtonVariant.Tertiary}
              onClick={useNavigatorWithURLReady(nextStep)}
              className="back-button"
            />
          </Col>
          <Text fontSize="T300">{getText(bundle, 'button-page-back')}</Text>
        </Row>
        {appointmentScheduling}
        <Text fontSize="T200">
          {getText(bundle, 'subheading-page-reschedule-appointment')}
        </Text>
      </Col>
      <Row gridGap="1rem">
        <Col width="100%" className="date-selector">
          <Text fontWeight="medium" fontSize="T300">
            {getText(bundle, 'heading-page-choose-date')}
          </Text>
          <Select
            id="date-selector"
            labelId="date-selector-label"
            placeholder={getText(bundle, 'reason-select-placeholder')}
            options={dates}
            value={selectedStartDate}
            onChange={onChangeDate}
          />
        </Col>
        {MLS_REQUIRED_LANGUAGE[stage] && (
        <Col width="100%" className="language-selector">
          <Text fontWeight="medium" fontSize="T300">
            {getText(bundle, 'banner-required-language')}
          </Text>
          <Select
            id="language-selector"
            labelId="language-selector-label"
            options={availableLocales}
            value={selectedLanguage}
            renderOption={(option) => getLanguageSupported(option, bundle, true)}
            onChange={onChangeLanguage}
          />
        </Col>
        )}
      </Row>

      <Col gridGap="1rem" className="available-time-range-list">
        <Text fontSize="T300" fontWeight="medium">
          {getText(bundle, 'heading-page-appointments-found')}
        </Text>
        <Text fontSize="T200">
          {getText(bundle, 'subheading-page-appointments-found')}
        </Text>
        {expanders}
        {modal}
      </Col>

      <Col width="100%">
        {cancelAppointment}
        {modal}
      </Col>
    </Col>
  );
}
