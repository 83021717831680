import React, { useState } from 'react';
import './styles.scss';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonSize } from '@amzn/stencil-react-components/button';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { getFormattedText } from 'src/utility/common';
import { Schedule } from 'src/utility/schedule-data';
import { ScheduleSelection } from './schedule-selection';
import { ScheduleSelectionBanner } from './schedule-selection-banner';

interface ScheduleSelectionGroupProps {
  schedules: Array<Schedule>;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleSelectionGroup({
  schedules,
  urlProps,
  bundle,
  stage,
}: ScheduleSelectionGroupProps) {
  const [index, setIndex] = useState(Math.min(5, schedules.length));
  let displayedSchedules = schedules.slice(0, index);

  const loadMoreSchedules = () => {
    const extraSchedulesCount = Math.min(5, schedules.length - index);
    displayedSchedules = schedules.slice(0, index + extraSchedulesCount);
    setIndex(index + extraSchedulesCount);
  };

  return (
    <Col gridGap="1rem">
      <ScheduleSelectionBanner schedule={schedules[0]} />
      <Col padding="0rem 1rem 1rem 1rem" gridGap="1rem">
        <Text className="display-number-of-shifts">
          {bundle
            ? getFormattedText(bundle, 'subheading-page-available-schedule', {
              current: index,
              total: schedules.length,
            })
            : `Showing ${index} of ${schedules.length} shifts`}
        </Text>
        <Col gridGap="1rem" className="schedule-selection-group">
          {displayedSchedules.map((schedule, index) => (
            <ScheduleSelection
              key={index}
              schedule={schedule}
              urlProps={urlProps}
              bundle={bundle}
              stage={stage}
            />
          ))}
        </Col>
        {index !== schedules.length && (
          <Row justifyContent="center">
            <Button
              size={ButtonSize.Small}
              onClick={loadMoreSchedules}
              className="show-more-button"
            >
              {getFormattedText(bundle, 'button-load-more-schedules', {
                0: Math.min(5, schedules.length - index),
              })}
            </Button>
          </Row>
        )}
      </Col>
    </Col>
  );
}
