export type Stage =
  | 'local'
  | 'beta'
  | 'beta-jp'
  | 'beta-ca'
  | 'gamma'
  | 'gamma-mx'
  | 'gamma-eu'
  | 'gamma-jp'
  | 'gamma-ca'
  | 'preprod-us'
  | 'preprod-ca'
  | 'preprod-mx'
  | 'preprod-eu'
  | 'preprod-jp'
  | 'prod-us'
  | 'prod-ca'
  | 'prod-mx'
  | 'prod-jp'
  | 'prod-eu';

export interface Settings {
  stage: Stage;
  apiEndpoint?: string;
  awsRumApplicationId?: string;
  awsRumMonitorRegion?: string;
  awsRumApplicationVersion?: string;
  awsRumIdentityPoolId?: string;
  awsRumGuestRoleArn?: string;
  country?: string;
}

declare global {
  export interface Window {
    dataLayerArray?: any[];
    CSS_settingsPromise?: Promise<Settings>;
    CSS_csrfPromise?: Promise<any>;
  }
}

const DEFAULT_SETTINGS: Settings = {
  stage: 'prod-us',
};

export function getCurrentCountryCode() {
  const { host } = document.location;
  // We should change this to be picked from HVHCESCommonPackage
  const DOMAIN_COUNTRY_MAP: Record<string, string> = {
    'beta-us.devo.jobsatamazon.hvh.a2z.com': 'US',
    'gamma-us.devo.jobsatamazon.hvh.a2z.com': 'US',
    'us.preprod.jobsatamazon.hvh.a2z.com': 'US',
    'hiring.amazon.com': 'US',
    'beta-mx.devo.jobsatamazon.hvh.a2z.com': 'MX',
    'gamma-mx.devo.jobsatamazon.hvh.a2z.com': 'MX',
    'mx.preprod.jobsatamazon.hvh.a2z.com': 'MX',
    'trabaja.amazon.com.mx': 'MX',
    'beta-ca.devo.jobsatamazon.hvh.a2z.com': 'CA',
    'gamma-ca.devo.jobsatamazon.hvh.a2z.com': 'CA',
    'ca.preprod.jobsatamazon.hvh.a2z.com': 'CA',
    'hiring.amazon.ca': 'CA',
    'beta-uk.devo.jobsatamazon.hvh.a2z.com': 'GB',
    'gamma-uk.devo.jobsatamazon.hvh.a2z.com': 'GB',
    'uk.preprod.jobsatamazon.hvh.a2z.com': 'GB',
    'www.jobsatamazon.co.uk': 'GB',
    'beta-jp.devo.jobsatamazon.hvh.a2z.com': 'JP',
    'gamma-jp.devo.jobsatamazon.hvh.a2z.com': 'JP',
    'jp.preprod.jobsatamazon.hvh.a2z.com': 'JP',
    'shigoto.amazon.co.jp': 'JP',
  };
  return DOMAIN_COUNTRY_MAP[host] || 'US';
}

function getSettingsCA(cssSettingsPromise: Settings): Settings {
  let stage: Stage;
  switch (cssSettingsPromise.stage) {
    case 'beta':
      stage = 'beta-ca';
      break;
    case 'gamma':
      stage = 'gamma-ca';
      break;
    case 'preprod-us':
      stage = 'preprod-ca';
      break;
    case 'prod-us':
      stage = 'prod-ca';
      break;
    default:
      stage = 'gamma-ca';
  }
  return {
    ...cssSettingsPromise,
    country: getCurrentCountryCode(),
    stage,
  };
}
export const getSettings = async (): Promise<Settings> => {
  try {
    window.CSS_settingsPromise = window.CSS_settingsPromise
      || fetch('/settings.json').then((result) => result.json());
    const cssSettingsPromise = await window.CSS_settingsPromise;
    if (getCurrentCountryCode() === 'CA') {
      return getSettingsCA(cssSettingsPromise);
    }
    return cssSettingsPromise;
  } catch (e) {
    console.error(e);
    return DEFAULT_SETTINGS;
  }
};
