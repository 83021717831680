import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// To support 'YYYY-MM-DD hh:mm A' on Firefox
dayjs.extend(customParseFormat);

export function getLeftBoundFromRange(range: string): string {
  const bounds = range.split('-');
  return bounds[0].trim();
}

export function getRightBoundFromRange(range: string): string {
  const bounds = range.split('-');
  return bounds[1].trim();
}

// 24 hours
export function convertToYYYYMMDDHHmm(date: string): dayjs.Dayjs {
  if (date.includes('undefined')) {
    return dayjs(date, '9999-12-31 09:00');
  }
  return dayjs(date, 'YYYY-MM-DD HH:mm');
}

// 12 hours
export function convertToYYYYMMDDhhmmA(date: string): dayjs.Dayjs {
  return dayjs(date, 'YYYY-MM-DD hh:mm A');
}

export function createDateFromTimeOnly(time: string | undefined): dayjs.Dayjs {
  if (!time) {
    return convertToYYYYMMDDhhmmA('9999-12-31 09:00 AM');
  }
  return time.includes('M')
    ? convertToYYYYMMDDhhmmA(`${'9999-12-31 '}${time}`)
    : convertToYYYYMMDDHHmm(`${'9999-12-31 '}${time}`);
}

export function compareLeftBounds(first: string, second: string): number {
  const dummyDate = '9999-12-31';
  const format = 'YYYY-MM-DD hh:mm A';
  return dayjs(`${dummyDate} ${first}`, format).isBefore(
    dayjs(`${dummyDate} ${second}`, format),
  )
    ? -1
    : 1;
}
