import React, { ComponentType, useState, useEffect } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useBundle } from '@amzn/react-arb-tools';
import { getSettings, Stage } from '../helpers/get-settings';
import { getText } from '../utility/common';

type ComponentWithStage = ComponentType<{
  stage: Stage;
  bundle: MessageBundle;
}>;

export const withStage = (Component: ComponentWithStage) => function ComponentWithStage(props: any) {
  const [stage, setStage] = useState<Stage>();
  const [bundle, isBundleLoading] = useBundle('main');

  useEffect(() => {
    getSettings().then((settings) => setStage(settings.stage));
  });

  if (!stage) {
    return null;
  }

  if (isBundleLoading) {
    return null;
  }

  // update webpage title
  const title = document.getElementById('title');
  if (title !== null) {
    title.innerHTML = getText(bundle, 'website-title');
  }

  return <Component {...props} stage={stage} bundle={bundle} />;
};
