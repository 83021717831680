import {
  buildUrl,
  CANCEL_APPOINTMENT,
  GET_AVAILABLE_TIME_SLOTS_BY_APPOINTMENT_TYPE_API,
  GET_CURRENT_APPLICATION_DETAILS_API,
  GET_CURRENT_TIME_SLOT_API_BY_APPT_TYPE,
  RESCHEDULE_APPOINTMENT,
} from '../config/urls';
import { fetcher } from '../helpers/fetcher';
import { validEUStage } from './common';
import {
  getLocale,
  spokenLanguageAlternativesToLocaleList,
} from './locale-data';
import { Application } from './schedule-data';
import { convertToYYYYMMDDHHmm } from './time';

export enum AppointmentType {
  NEW_HIRE_EVENT = 'NEW_HIRE_EVENT',
  RIGHT_TO_WORK = 'RIGHT_TO_WORK',
  DRUG_TEST = 'DRUG_TEST',
  DRUG_TEST_RETAKE = 'DRUG_TEST_RETAKE',
}

export enum AppointmentTypePath {
  NEW_HIRE_EVENT = 'new-hire-event',
  RIGHT_TO_WORK = 'right-to-work',
  DRUG_TEST = 'drug-test',
  DRUG_TEST_RETAKE = 'drug-test-retake',
}

export const DEFAULT_APPOINTMENT_TYPE_PATH = AppointmentTypePath.NEW_HIRE_EVENT;

export const PathToAppointmentType = {
  [AppointmentTypePath.NEW_HIRE_EVENT]: AppointmentType.NEW_HIRE_EVENT,
  [AppointmentTypePath.RIGHT_TO_WORK]: AppointmentType.RIGHT_TO_WORK,
  [AppointmentTypePath.DRUG_TEST]: AppointmentType.DRUG_TEST,
  [AppointmentTypePath.DRUG_TEST_RETAKE]: AppointmentType.DRUG_TEST_RETAKE,
};

// Refer https://code.amazon.com/packages/HVHHiringEventsInterface/blobs/mainline/--/src/com/amazon/hvhhiringevents/formats/nhe/Status.java for list of possible Status
export enum AppointmentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  DRAFT = 'DRAFT',
  RESERVED = 'RESERVED',
  RELEASED = 'RELEASED',
  ARRIVED = 'ARRIVED',
  NO_SHOW = 'NO_SHOW',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export interface SimplifiedTimeSlot {
  readonly timeSlotId: string;
  readonly venueId: string;
  readonly startDate: string;
  readonly startTime: string;
  readonly endDate: string;
  readonly endTime: string;
  readonly locales: Array<string>;
  readonly locationType?: string;
  readonly displayReadyLocation?: string;
  readonly displayReadyTimeSlot?: string;
  readonly virtualLink?: string;
}

export interface SpokenLocaleAlternative {
  language: string;
  territory: string;
}

export interface TimeSlot {
  readonly errorCode?: number | string;
  readonly errorMessage?: string;
  readonly timeSlotId: string;
  readonly status: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly availableAppointments: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly venueId: string;
  readonly spokenLocaleAlternatives?: SpokenLocaleAlternative[];
  readonly locationType?: string;
  readonly virtualLink?: string;
  readonly displayReadyLocation?: string;
  readonly displayReadyTimeSlot?: string;
}

export interface AvailableTimeSlots {
  errorCode?: number
  errorMessage?: string
  timeSlots: Array<TimeSlot>;
}

export interface RescheduleAppointmentRequest {
  applicationId: string;
  appointmentType: string;
  venueId: string;
  timeSlotId: string;
  userAlias: string;
}

export interface RescheduleAppointmentResponse {
  errorCode?: number;
  errorMessage?: string;
  applicationId: string;
  venueId: string;
  timeSlotId: string;
}

export interface CancelAppointmentRequest {
  applicationId: string;
  appointmentType: string;
  appointmentStatus: string;
  userAlias: string;
  requestingService: string;
  timeSlotId: string;
}

export interface CancelAppointmentResponse {
  errorCode?: number;
  operationStatus: string;
}

export interface Reason {
  text: string;
  value: string;
  label: string;
}

export const cancelRtwReasons = [
  {
    text: 'I found/accepted an opportunity outside of Amazon',
    value: 'cancellation-found-an-opportunity-outside-amazon',
    label: 'rtw-cancellation-reason-1',
  },
  {
    text: 'I found/accepted another opportunity at Amazon',
    value: 'cancellation-found-another-opportunity-at-amazon',
    label: 'rtw-cancellation-reason-2',
  },
  {
    text: 'I no longer want to work for Amazon',
    value: 'cancellation-no-longer-want-to-work-at-amazon',
    label: 'rtw-cancellation-reason-3',
  },
  {
    text: 'I cannot find a time/schedule that works for me',
    value: 'cancellation-no-working-schedule',
    label: 'rtw-cancellation-reason-4',
  },
  {
    text: 'I cannot find a start date that works for me',
    value: 'cancellation-cannot-find-start-date',
    label: 'rtw-cancellation-reason-5',
  },
  {
    text: 'This location does not work for me',
    value: 'cancellation-location-does-not-work',
    label: 'rtw-cancellation-reason-6',
  },
  {
    text: 'None of the above',
    value: 'cancellation-none-of-the-above',
    label: 'rtw-update-or-cancel-common-reason-1',
  },
] as Array<Reason>;

const getEmptyAppointmentCache = () => ({
  [AppointmentType.NEW_HIRE_EVENT]: {},
  [AppointmentType.RIGHT_TO_WORK]: {},
  [AppointmentType.DRUG_TEST]: {},
  [AppointmentType.DRUG_TEST_RETAKE]: {},
});

let cacheForCurrentTimeSlot: Record<string, Record<string, Promise<TimeSlot>>> = getEmptyAppointmentCache();

let cacheForAvailableTimeSlots: Record<string, Record<string, Promise<AvailableTimeSlots>>> = getEmptyAppointmentCache();

let cacheForCurrentApplicationDetails: Record<string, Promise<Application>> = {};

export const deleteCachedRequest = () => {
  cacheForCurrentTimeSlot = getEmptyAppointmentCache();
  cacheForAvailableTimeSlots = getEmptyAppointmentCache();
  cacheForCurrentApplicationDetails = {};
};

export const organizeTimeSlots = (
  timeSlots: Array<TimeSlot>,
  curTimeSlot?: TimeSlot,
): [Map<string, Map<string, Array<SimplifiedTimeSlot>>>, Array<string>] => {
  const map = new Map<string, Map<string, Array<SimplifiedTimeSlot>>>();
  const availableLocale: Set<string> = new Set<string>();
  for (let i = 0; i < timeSlots.length; i += 1) {
    const timeSlot = timeSlots[i];
    if (
      timeSlot.venueId === curTimeSlot?.venueId
      && timeSlot.timeSlotId === curTimeSlot?.timeSlotId
    ) {
      continue;
    }
    const currentTime = convertToYYYYMMDDHHmm(
      `${timeSlot.startDate} ${timeSlot.startTime}`,
    );
    const leftBound = currentTime.format('hh:00 A');
    const rightBound = currentTime.add(1, 'hour').format('hh:00 A');
    const timeRange = `${leftBound} - ${rightBound}`;
    if (!map.has(timeSlot.startDate)) {
      const nestedMap = new Map<string, Array<SimplifiedTimeSlot>>();
      map.set(timeSlot.startDate, nestedMap);
    }
    const nestedMap = map.get(timeSlot.startDate);
    if (!map.get(timeSlot.startDate)?.has(timeRange)) {
      nestedMap?.set(timeRange, []);
    }

    const localeList = spokenLanguageAlternativesToLocaleList(
      timeSlot.spokenLocaleAlternatives,
    );

    nestedMap?.get(timeRange)?.push({
      timeSlotId: timeSlot.timeSlotId,
      venueId: timeSlot.venueId,
      startDate: timeSlot.startDate,
      startTime: timeSlot.startTime,
      endDate: timeSlot.endDate,
      endTime: timeSlot.endTime,
      virtualLink: timeSlot.virtualLink,
      displayReadyLocation: timeSlot.displayReadyLocation,
      displayReadyTimeSlot: timeSlot.displayReadyTimeSlot,
      locationType: timeSlot.locationType,
      locales: localeList,
    } as SimplifiedTimeSlot);

    localeList.forEach((locale) => availableLocale.add(locale));
  }
  return [map, Array.from(availableLocale.values())];
};

export const getCurrentTimeSlotByAppId = (
  applicationId?: string,
  appointmentType: AppointmentType = AppointmentType.NEW_HIRE_EVENT,
): Promise<TimeSlot> => {
  if (!applicationId) {
    throw new Error('applicationId should be provided');
  }
  if (!cacheForCurrentTimeSlot[appointmentType][applicationId]) {
    const url = buildUrl(GET_CURRENT_TIME_SLOT_API_BY_APPT_TYPE, {
      applicationId,
      locale: getLocale(),
      appointmentType,
    });
    cacheForCurrentTimeSlot[appointmentType][applicationId] = fetcher(url);
  }
  return cacheForCurrentTimeSlot[appointmentType][applicationId];
};

export const getCurrentRtwTimeSlotByAppId = (
  applicationId?: string,
): Promise<TimeSlot> => getCurrentTimeSlotByAppId(
  applicationId,
  AppointmentType.RIGHT_TO_WORK,
);

export const getAvailableTimeSlotByAppId = (
  applicationId?: string,
  appointmentType: AppointmentType = AppointmentType.NEW_HIRE_EVENT,
): Promise<AvailableTimeSlots> => {
  if (!applicationId) {
    throw new Error('applicationId should be provided');
  }
  const locale = getLocale();
  if (!cacheForAvailableTimeSlots[appointmentType][applicationId]) {
    const url = buildUrl(GET_AVAILABLE_TIME_SLOTS_BY_APPOINTMENT_TYPE_API, {
      applicationId,
      appointmentType,
      locale,
    });
    cacheForAvailableTimeSlots[appointmentType][applicationId] = fetcher(url);
  }
  return cacheForAvailableTimeSlots[appointmentType][applicationId];
};

export const getCurrentApplicationDetailsByAppId = (
  applicationId?: string,
): Promise<Application> => {
  if (!applicationId) {
    throw new Error('applicationId should be provided');
  }
  if (!cacheForCurrentApplicationDetails[applicationId]) {
    const url = buildUrl(GET_CURRENT_APPLICATION_DETAILS_API, {
      applicationId,
    });
    cacheForCurrentApplicationDetails[applicationId] = fetcher(url);
  }
  return cacheForCurrentApplicationDetails[applicationId];
};

export const rescheduleAppointment = (
  request?: RescheduleAppointmentRequest,
): Promise<RescheduleAppointmentResponse> => {
  if (!request) {
    throw new Error('request should be provided');
  }
  return fetcher(buildUrl(RESCHEDULE_APPOINTMENT), {
    method: 'POST',
    body: JSON.stringify(request),
  });
};

export const cancelAppointment = (
  request?: CancelAppointmentRequest,
): Promise<CancelAppointmentResponse> => {
  if (!request) {
    throw new Error('request should be provided');
  }
  return fetcher(buildUrl(CANCEL_APPOINTMENT), {
    method: 'POST',
    body: JSON.stringify(request),
  });
};

export const validAppStatesForSchedulingNHE = [
  'PENDING_APPT_1_SCHEDULING',
  'EVALUATION_PENDING',
];

export function validNHEPooling(
  applicationState: string | undefined,
  stage: string,
  appointmentType?: AppointmentType,
) {
  if (appointmentType !== AppointmentType.NEW_HIRE_EVENT) {
    return true;
  }
  if (!applicationState) {
    return false;
  }
  return (
    validAppStatesForSchedulingNHE.includes(applicationState)
      && validEUStage(stage)
  );
}
