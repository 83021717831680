import React, { useMemo, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Card } from '@amzn/stencil-react-components/card';
import { ExpanderContent } from '@amzn/stencil-react-components/expander';
import { TriggerButton } from '@amzn/stencil-react-components/helpers';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import DOMPurify from 'dompurify';
import {
  DISPLAY_HOURLY_PAYMENT,
  MLS_REQUIRED_LANGUAGE,
} from 'src/config/feature-control';
import {
  scheduleCardBannerColor,
  surgePayBannerColor,
  surgePayBannerTextColor,
} from 'src/config/palette';
import { Stage } from 'src/helpers/get-settings';
import { getText } from 'src/utility/common';
import { getLanguageSupported } from 'src/utility/locale-data';
import { Schedule } from 'src/utility/schedule-data';
import './styles.scss';
import { VideoComponent } from '../video';

interface ScheduleContainerProps {
  schedule: Schedule;
  displayPreviewInfo: boolean;
  title?: string;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleContainer({
  schedule,
  displayPreviewInfo,
  title,
  bundle,
  stage,
}: ScheduleContainerProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hourText = schedule.scheduleText.split('\n');
  const purifiedHtml = useMemo(
    () => DOMPurify.sanitize(schedule.jobDescription, { FORBID_ATTR: ['class'] }),
    [schedule.jobDescription],
  );
  return (
    <Col gridGap="1rem">
      {title && <Text fontSize="T400">{title}</Text>}
      <Card padding="0rem">
        <Col width="100%">
          <Row alignItems="center" backgroundColor={scheduleCardBannerColor}>
            <Col padding="1rem" alignItems="center">
              <img
                className="schedule-image"
                alt="Icon"
                src={schedule.image}
                width="70px"
              />
              {schedule.signOnBonusL10N && (
                <Col className="sign-on-bonus-banner">
                  <Text color={surgePayBannerTextColor} fontSize="10px">
                    {getText(bundle, 'schedule-card-field-bonus')}
                    {' '}
                    {schedule.signOnBonusL10N}
                  </Text>
                </Col>
              )}
            </Col>

            <Col padding="1rem 1rem 1rem 0" gridGap="0.2rem">
              <Text
                fontSize="T200"
                fontWeight="bold"
                className="schedule-title"
              >
                {schedule.externalJobTitle}
              </Text>
              <Text fontSize="T200" className="schedule-card-description">
                {schedule.briefJobDescription}
              </Text>
              {schedule.scheduleBannerText && (
                <Row className="surge-pay-banner">
                  <Col
                    backgroundColor={surgePayBannerColor}
                    padding="0rem 0.5rem 0rem 0.5rem"
                  >
                    <Text color={surgePayBannerTextColor} fontSize="T200">
                      {schedule.scheduleBannerText}
                    </Text>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          {displayPreviewInfo && (
            <VideoComponent
              src={schedule.jobPreviewVideo}
              controls={false}
              crossOrigin="anonymous"
              type="video/mp4"
            />
          )}

          <Col gridGap="1rem" padding="1rem">
            <Row className="schedule-hours">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-hours')}
                  :
                </strong>
                {' '}
                {schedule.hoursPerWeek}
                {' '}
                {getText(bundle, 'schedule-card-field-hours-unit')}
              </Text>
            </Row>
            <Row className="schedule-duration">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-duration')}
                  :
                </strong>
                {' '}
                {schedule.employmentTypeL10N}
              </Text>
            </Row>
            <Row className="schedule-shift">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-shift')}
                  :
                </strong>
                &nbsp;
              </Text>
              <Col>
                <Text fontSize="T200">
                  {hourText.map((text: string) => (
                    <Row key={text}>{text}</Row>
                  ))}
                </Text>
                {schedule.parsedTrainingDate && (
                  <>
                    <Text fontSize="T200">
                      <em>
                        {getText(bundle, 'schedule-card-field-training-dates')}
                      </em>
                    </Text>
                    <Text fontSize="T200">
                      <pre>{schedule.parsedTrainingDate}</pre>
                    </Text>
                  </>
                )}
              </Col>
            </Row>
            <Row className="schedule-pay-rate">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-pay-rate')}
                  :
                </strong>
                {' '}
                {DISPLAY_HOURLY_PAYMENT[stage] ? (
                  <>
                    {schedule.totalPayRateL10N}
                    {getText(bundle, 'schedule-card-field-pay-rate-unit')}
                  </>
                ) : (
                  <>
                    {schedule.monthlyBasePayL10N}
                    {getText(bundle, 'schedule-card-field-pay-rate-unit-month')}
                  </>
                )}
              </Text>
            </Row>

            <Row className="schedule-start-date">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-start-date')}
                  :
                </strong>
                {' '}
                {schedule.firstDayOnSiteL10N}
              </Text>
            </Row>

            <Row className="schedule-address">
              <Text fontSize="T200">
                <strong>
                  {getText(bundle, 'schedule-card-field-work-address')}
                  :
                </strong>
                {' '}
                {schedule.address}
                ,
                {schedule.city}
                ,
                {schedule.state}
                {' '}
                {schedule.postalCode}
              </Text>
            </Row>

            {MLS_REQUIRED_LANGUAGE[stage] && schedule.requiredLanguage && (
              <Row className="schedule-required-language">
                <Text fontSize="T200">
                  <strong>
                    {getText(bundle, 'schedule-card-field-required-language')}
                    :
                  </strong>
                  {' '}
                  {getLanguageSupported(schedule.requiredLanguage, bundle)}
                </Text>
              </Row>
            )}

            {displayPreviewInfo && (
              <Col gridGap="0.5rem" className="schedule-requirements">
                <Row alignItems="center" gridGap="0.5rem">
                  <Text
                    className="expanderLabel"
                    fontSize="T300"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {getText(bundle, 'schedule-card-field-job-requirement')}
                  </Text>
                  <TriggerButton
                    iconAltText="trigger"
                    isExpanded={isExpanded}
                    onClick={() => setIsExpanded(!isExpanded)}
                  />
                </Row>
                <ExpanderContent isExpanded={isExpanded}>
                  <Text fontSize="T200">
                    <div
                      id="job-requirement-html-container"
                      dangerouslySetInnerHTML={{
                        __html: purifiedHtml,
                      }}
                    />
                  </Text>
                </ExpanderContent>
              </Col>
            )}
          </Col>
        </Col>
      </Card>
    </Col>
  );
}
