import { MessageBundle } from '@amzn/arb-tools';
import main from '../text/main.puff.json';
import { getLocale } from './locale-data';

export const format24Region = ['en-GB', 'fr-CA'];
export const formattedText: Map<string, string> = new Map([
  [
    'banner-current-appointment-details',
    'banner-current-appointment-details-24',
  ],
  [
    'subheading-modal-appointment-cancel',
    'subheading-modal-appointment-cancel-24',
  ],
  ['date-format-time', 'date-format-time-24'],
  ['date-format-timeA', 'date-format-time-24'],
]);

export function delay(ms: number) {
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}

export function getURLParams(url: string) {
  const query = url.split('#')[0];
  const params = query.split('/').filter((element) => element);
  const len = params.length;
  return {
    applicationId: params[len - 2],
    jobId: params[len - 1],
  };
}

export function deepCopy(object: any) {
  return JSON.parse(JSON.stringify(object));
}

export const pantherResources = JSON.parse(JSON.stringify(main));
export function getText(bundle: any, text: string) {
  if (!bundle) {
    if (pantherResources.resources[text].value) {
      return pantherResources.resources[text].value;
    }
    return pantherResources.resources[text];
  }
  return bundle.getMessage(text);
}
export function findCorrectFormat(text: string): string {
  const region = getLocale();
  if (formattedText.has(text) && format24Region.includes(region)) {
    const regionText = formattedText.get(text);
    return regionText || text;
  }
  return text;
}

export function getFormattedText(
  bundle: MessageBundle | undefined,
  text: string,
  args: Record<string, unknown>,
) {
  const newText = findCorrectFormat(text);
  if (!bundle) {
    if (pantherResources.resources[newText].value) {
      return pantherResources.resources[newText].value;
    }
    return pantherResources.resources[newText];
  }
  return bundle.formatMessage(newText, args);
}

export function validEUStage(stage: string) {
  return stage?.includes('-eu');
}
