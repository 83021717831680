import React from 'react';
import {
  Text as StencilText,
  TextProps,
  H1 as StencilH1,
  H2 as StencilH2,
  H3 as StencilH3,
  Label as StencilLabel,
} from '@amzn/stencil-react-components/text';
import { labelColor } from 'src/config/palette';

export function H1({ children, ...props }: TextProps) {
  return (
    <StencilH1 fontWeight="regular" fontSize="T500" {...props}>
      {children}
    </StencilH1>
  );
}

export function H2({ children, ...props }: TextProps) {
  return (
    <StencilH2 fontWeight="regular" fontSize="T400" {...props}>
      {children}
    </StencilH2>
  );
}

export function H3({ children, ...props }: TextProps) {
  return (
    <StencilH3 fontWeight="regular" fontSize="T300" {...props}>
      {children}
    </StencilH3>
  );
}

export function Text({ children, ...props }: TextProps) {
  return (
    <StencilText fontWeight="regular" fontSize="T200" {...props}>
      {children}
    </StencilText>
  );
}

export function Label({ children, ...props }: any) {
  return (
    <StencilLabel fontSize="T200" color={labelColor} {...props}>
      {children}
    </StencilLabel>
  );
}
