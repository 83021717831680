import React from 'react';
import './styles.scss';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import {
  appointmentBannerBackgroundColor,
  surgePayBannerColor,
  surgePayBannerTextColor,
} from 'src/config/palette';
import { Schedule } from 'src/utility/schedule-data';

interface ScheduleSelectionBannerProps {
  schedule: Schedule;
}

export function ScheduleSelectionBanner({
  schedule,
}: ScheduleSelectionBannerProps) {
  return (
    <Col
      backgroundColor={appointmentBannerBackgroundColor}
      padding="1rem"
      gridGap="0.5rem"
      border="groove"
    >
      <Text fontSize="T200" className="schedule-title">
        <strong>{schedule.externalJobTitle}</strong>
      </Text>
      <Text fontSize="T200" className="schedule-address">
        {schedule.address}
        ,
        {schedule.city}
        ,
        {schedule.state}
        {' '}
        {schedule.postalCode}
      </Text>
      {schedule.scheduleBannerText && (
        <Row className="surge-pay-banner">
          <Col
            backgroundColor={surgePayBannerColor}
            padding="0rem 0.5rem 0rem 0.5rem"
          >
            <Text
              color={surgePayBannerTextColor}
              fontSize="T200"
              id="schedule-card-hours"
            >
              {schedule.scheduleBannerText}
            </Text>
          </Col>
        </Row>
      )}
    </Col>
  );
}
