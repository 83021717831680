import React, { useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { InputWrapper, Select } from '@amzn/stencil-react-components/form';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import {
  APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL,
  buildUrl,
  CANDIDATE_SUPPORT_LINK,
  SELF_SERVICE_CANCEL_COMPLETE_URL,
  SELF_SERVICE_UPDATE_FAILURE_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getFormattedText, getText } from 'src/utility/common';
import { Reason } from 'src/utility/schedule-data';
import { CSS_ALIAS } from '../../config/constants';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import {
  AppointmentStatus,
  AppointmentType,
  AppointmentTypePath,
  cancelAppointment,
  CancelAppointmentRequest,
  cancelRtwReasons,
  TimeSlot,
} from '../../utility/appointment-data';
import { AppointmentBanner } from '../appointment-card/appointment-banner';
import { BackButton } from '../custom-button/back-button';

interface CancelConfirmationControlledProps {
  loading: boolean;
  error: boolean;
  timeslot?: TimeSlot;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function CancelConfirmationControlled({
  loading,
  error,
  timeslot,
  urlProps,
  bundle,
  stage,
}: CancelConfirmationControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(bundle, 'banner-error-current-rtw-fails-to-retrieve', {
        support: supportLink,
      })}
    </MessageBanner>
  );

  if (error || !timeslot) {
    return errorMessage;
  }

  const [updating, setUpdating] = useState<boolean>(false);
  const [reason, setReason] = useState<Reason>();
  const [reasonError, setReasonError] = useState<boolean>(false);

  const getFooterText = () => {
    if (reasonError) {
      return getText(bundle, 'banner-error-reason-not-selected');
    }
    return undefined;
  };

  const urlToCancelRtwCompletePage = buildUrl(
    SELF_SERVICE_CANCEL_COMPLETE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
      appointmentTypePath: AppointmentTypePath.RIGHT_TO_WORK,
    },
  );

  const urlToUpdateRtwFailurePage = buildUrl(SELF_SERVICE_UPDATE_FAILURE_URL, {
    applicationId: urlProps.applicationId,
    jobId: urlProps.jobId,
    appointmentTypePath: AppointmentTypePath.RIGHT_TO_WORK,
  });

  const urlToCurrentRtwPage = buildUrl(
    APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
      appointmentTypePath: AppointmentTypePath.RIGHT_TO_WORK,
    },
  );

  const sendAdobeEvents = (eventName: string, reason: string) => {
    adobeAnalytics.addEventMetric(
      window,
      eventName,
      urlProps.applicationId as string,
      urlProps.jobId as string,
      {
        cancellationReschedule: {
          reason,
        },
      },
    );
  };

  const cancelRtw = async () => {
    if (!reason) {
      setReasonError(true);
    } else {
      setUpdating(true);
      try {
        const request = {
          applicationId: urlProps.applicationId,
          appointmentType: AppointmentType.RIGHT_TO_WORK,
          appointmentStatus: AppointmentStatus.RELEASED,
          userAlias: CSS_ALIAS,
          timeSlotId: timeslot?.timeSlotId,
          requestingService: CSS_ALIAS,
        } as CancelAppointmentRequest;
        const response = await cancelAppointment(request);
        if (response?.errorCode) {
          sendAdobeEvents(
            'fail to cancel RTW on self service page',
            reason.value,
          );
          window.location.assign(urlToUpdateRtwFailurePage);
        } else {
          sendAdobeEvents(
            'successfully cancel RTW on self service page',
            reason.value,
          );
          window.location.assign(urlToCancelRtwCompletePage);
        }
      } catch (err) {
        sendAdobeEvents(
          'fail to cancel RTW on self service page',
          reason.value,
        );
        window.location.assign(urlToUpdateRtwFailurePage);
      }
    }
  };

  const confirmation = (
    <Col gridGap="S300">
      <Row alignItems="center">
        <BackButton url={urlToCurrentRtwPage} bundle={bundle} />
      </Row>
      <Col gridGap="S300">
        <AppointmentBanner timeSlot={timeslot} bundle={bundle} stage={stage} />
        <Col gridGap="S300" className="cancellation-confirmation">
          <Text>
            <strong>
              {getText(bundle, 'confirmation-question-page-cancel-appointment')}
            </strong>
          </Text>
          <Text>{getText(bundle, 'description-page-cancel-appointment')}</Text>
        </Col>
        <Col gridGap="S200" className="rtw-cancellation-reason-selection">
          <InputWrapper
            id="cancel-rtw-reason-wrapper"
            labelText={getText(
              bundle,
              'reason-question-page-cancel-appointment',
            )}
            footer={getFooterText()}
            error={reasonError}
            required
          >
            {(inputProps) => (
              <Select
                options={cancelRtwReasons}
                renderOption={(option) => getText(bundle, option.label)}
                placeholder={getText(bundle, 'reason-select-placeholder')}
                {...inputProps}
                onChange={(option: Reason) => {
                  setReason(option);
                  setReasonError(false);
                }}
              />
            )}
          </InputWrapper>
        </Col>
        <Col gridGap="S300">
          <Button
            variant={ButtonVariant.Primary}
            onClick={cancelRtw}
            className="confirm-button"
          >
            {getText(bundle, 'button-modal-confirm')}
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={useNavigatorWithURLReady(urlToCurrentRtwPage)}
            className="cancel-button"
          >
            {getText(bundle, 'button-modal-cancel')}
          </Button>
        </Col>
      </Col>
    </Col>
  );

  const spinner = (
    <Col alignItems="center">
      <Spinner
        size={SpinnerSize.Small}
        loadingText={getText(bundle, 'page-updating-message')}
        showText
      />
    </Col>
  );

  return (
    <>
      {updating && spinner}
      {!updating && confirmation}
    </>
  );
}
