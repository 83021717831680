import { MessageBundle } from '@amzn/arb-tools';
import * as globalLocaleConfigMap from '@amzn/hvhlocale-configuration-jshelper/lib/hvh-locale-configuration-value-global.json';
import { IHVHLocaleConfigurations } from '@amzn/hvhlocale-configuration-jshelper/lib/interfaces';
import * as Cookie from 'js-cookie';
import { getSettings, Settings } from '../helpers/get-settings';
import { AwsRumClient } from '../helpers/rum/awsRumClient';
import { SpokenLocaleAlternative } from './appointment-data';
import { getText } from './common';

export const DEFAULT_LOCALE_US = 'en-US';

// Please update below configs for country expansions
export const localeToLanguage: Map<string, string> = new Map([
  ['en-US', 'language-display-english'],
  ['en-CA', 'language-display-english'],
  ['en-GB', 'language-display-english'],
  ['es-US', 'language-display-spanish'],
  ['es-MX', 'language-display-spanish'],
  ['fr-CA', 'language-display-french'],
]);

export const localeToLanguageInItsOwn: Map<string, string> = new Map([
  ['en-US', 'English'],
  ['en-GB', 'English'],
  ['es-US', 'Español'],
  ['es-MX', 'Español'],
  ['en-CA', 'English'],
  ['fr-CA', 'Français'],
]);

let settings: Settings;
(async () => {
  settings = await getSettings();
})();
// source file: https://code.amazon.com/packages/HVHLocaleConfiguration/blobs/mainline/--/configuration/hvh-locale-configuration/hvh-locale-configuration-value-global.json
export const getCountryCodeCookie = (): string => {
  const countryCodeCookie = Cookie.get('hvh-country-code');
  if (countryCodeCookie) {
    return countryCodeCookie;
  } if (settings?.country) {
    AwsRumClient.recordEvent(
      'settings-locale-used',
      `SETTINGS_${settings.country}`,
    );
    return settings.country;
  }
  AwsRumClient.recordEvent('default-locale-used', 'DEFAULT_US');
  return 'US';
};

function getLocaleFromCountryCode(): string {
  const countryCode = getCountryCodeCookie();
  return (globalLocaleConfigMap as IHVHLocaleConfigurations)[countryCode]['default-locale'].toString();
}

export function getLocale() {
  const hvhLocaleFromCookie = Cookie.get('hvh-locale');
  const hvhDefaultLocaleFromCookie = Cookie.get('hvh-default-locale');
  const localeFromCountryCodeCookie = getLocaleFromCountryCode();

  return hvhLocaleFromCookie || (hvhDefaultLocaleFromCookie || localeFromCountryCodeCookie);
}

export function spokenLanguageAlternativesToLocaleList(
  spokenLocaleAlternatives: Array<SpokenLocaleAlternative> | undefined,
) {
  const locales: string[] = [];
  if (spokenLocaleAlternatives) {
    spokenLocaleAlternatives.forEach((e) => {
      locales.push(`${e.language}-${e.territory}`);
    });
  }
  if (locales.length === 0) {
    locales.push(getLocale());
  }
  return locales;
}

export function getLanguageSupported(
  locales: string | Array<string>,
  bundle: MessageBundle | undefined,
  languageNamesInTheirOwnLanguage?: boolean,
) {
  /*
   * expected inputs:
   * 1. "[en-US, es-US]"
   * 2. ["en-US", "es-US"]
   * 3. "en-US, es-US"
   */
  let localeList = locales;
  if (typeof locales === 'string') {
    localeList = locales.startsWith('[')
      ? locales.substring(1, locales.length - 1).split(',')
      : locales.split(',');
  }
  let languages = '';
  for (let i = 0; i < localeList.length; i += 1) {
    if (languageNamesInTheirOwnLanguage) {
      languages += `${localeToLanguageInItsOwn.get(localeList[i].trim())}, `;
    } else {
      const languageLabel = localeToLanguage.get(localeList[i].trim());
      if (languageLabel) {
        languages += `${getText(bundle, languageLabel)}, `;
      }
    }
  }
  return languages.length === 0
    ? languages
    : languages.substr(0, languages.length - 2);
}
