import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import {
  buildUrl,
  CANDIDATE_SUPPORT_LINK,
  SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { getFormattedText, getText } from 'src/utility/common';
import { Schedule } from 'src/utility/schedule-data';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import { BackButton } from '../custom-button/back-button';
import { FilterButton } from '../custom-button/filter-button';
import { SortButton } from '../custom-button/sort-button';
import { ScheduleSelectionGroup } from './schedule-selection-group';

interface ScheduleListControlledProps {
  schedules?: Array<Array<Schedule>>;
  loading: boolean;
  error: boolean;
  openFilterFlyout: () => void;
  openSortFlyout: () => void;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}
export function ScheduleListControlled({
  schedules,
  loading,
  error,
  openFilterFlyout,
  openSortFlyout,
  urlProps,
  bundle,
  stage,
}: ScheduleListControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(
        bundle,
        'banner-error-available-schedule-fails-to-retrieve',
        {
          support: supportLink,
        },
      )}
    </MessageBanner>
  );

  if (error || !schedules) {
    adobeAnalytics.addEventMetric(
      window,
      'get all available shift error on self service page',
      urlProps.applicationId as string,
      urlProps.jobId as string,
    );
    return errorMessage;
  }

  if (schedules.length === 0) {
    adobeAnalytics.addEventMetric(
      window,
      'no available shift on self service page',
      urlProps.applicationId as string,
      urlProps.jobId as string,
    );
  }

  const urlToCurrentSchedulePage = buildUrl(
    SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  return (
    <Col gridGap="1rem">
      <Row alignItems="center">
        <BackButton url={urlToCurrentSchedulePage} bundle={bundle} />
      </Row>
      <Row padding="0rem 1rem 0rem 1rem" alignItems="center">
        <Text fontSize="T400">
          {getText(bundle, 'heading-page-available-schedule')}
        </Text>
      </Row>
      <Col>
        <Row
          padding="0rem 1rem 0rem 1rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <FilterButton onClick={openFilterFlyout} bundle={bundle} />
          <SortButton onClick={openSortFlyout} bundle={bundle} />
        </Row>
        {schedules.length === 0 && (
          <MessageBanner type={MessageBannerType.Informational}>
            {getText(bundle, 'banner-error-no-available-schedule-found')}
          </MessageBanner>
        )}
        <Col gridGap="1rem">
          {schedules.map((schedules, index) => (
            <ScheduleSelectionGroup
              key={index}
              schedules={schedules}
              urlProps={urlProps}
              bundle={bundle}
              stage={stage}
            />
          ))}
        </Col>
      </Col>
    </Col>
  );
}
