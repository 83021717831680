import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/button';
import { IconSort } from '@amzn/stencil-react-components/icons';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { getText } from 'src/utility/common';

interface SortButtonProps {
  onClick: () => void;
  bundle: MessageBundle | undefined;
}

export function SortButton({ onClick, bundle }: SortButtonProps) {
  return (
    <Button
      icon={(
        <Row gridGap="1rem" alignItems="center">
          <IconSort title="iconSort" />
          <Text fontSize="T300">{getText(bundle, 'button-sort')}</Text>
        </Row>
      )}
      size={ButtonSize.Default}
      variant={ButtonVariant.Tertiary}
      onClick={onClick}
      className="sort-button"
    />
  );
}
