import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { isPastAppointmentError } from 'src/helpers/error.helpers';
import { Stage } from 'src/helpers/get-settings';
import {
  AppointmentType,
  TimeSlot,
  validNHEPooling,
} from 'src/utility/appointment-data';
import { Application } from 'src/utility/schedule-data';
import { AppointmentCardControlled } from './card-controlled';

interface AppointmentCardUncontrolledProps {
  currentTimeSlotAccessor: () => Promise<TimeSlot | undefined>;
  getApplicationDetail: () => Promise<Application | undefined>;
  appointmentType?: AppointmentType;
  checklistURL: string;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function AppointmentCardUncontrolled({
  appointmentType,
  currentTimeSlotAccessor,
  getApplicationDetail,
  checklistURL,
  bundle,
  stage,
}: AppointmentCardUncontrolledProps) {
  const [timeSlot, setTimeSlot] = useState<TimeSlot>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number | string>();
  const [applicationState, setApplicationState] = useState<string>();

  const requestBackendData = useCallback(async () => {
    setLoading(true);
    try {
      const currentTimeSlot = await currentTimeSlotAccessor();
      const currentApplication = await getApplicationDetail();

      if (!currentApplication?.errorCode) {
        setApplicationState(currentApplication?.applicationState);
      }

      if (
        currentTimeSlot?.errorCode
        && (isPastAppointmentError(currentTimeSlot?.errorCode)
          || !validNHEPooling(applicationState, stage, appointmentType))
      ) {
        setError(true);
        setErrorCode(currentTimeSlot?.errorCode);
      } else if (!currentTimeSlot?.errorCode) {
        setTimeSlot(currentTimeSlot);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    requestBackendData();
  }, []);

  return (
    <AppointmentCardControlled
      timeSlot={timeSlot}
      appointmentType={appointmentType}
      applicationState={applicationState}
      error={error}
      errorCode={errorCode}
      loading={loading}
      checklistURL={checklistURL}
      bundle={bundle}
      stage={stage}
    />
  );
}
