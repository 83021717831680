import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import {
  getCurrentApplicationDetailsByAppId,
  getCurrentScheduleDetailsByAppId,
  getScheduleDetailByScheduleId,
} from 'src/utility/schedule-data';
import { ScheduleUpdateConfirmationUncontrolled } from './card-uncontrolled';

interface ScheduleUpdateConfirmationProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function ScheduleUpdateConfirmation({
  bundle,
  stage,
}: ScheduleUpdateConfirmationProps) {
  const { applicationId, jobId, scheduleId } = useParams<{
  applicationId: string;
  jobId: string;
  scheduleId: string;
  }>();

  const urlProps = {
    applicationId,
    jobId,
    scheduleId,
  } as UrlProps;

  const getScheduleDetail = useCallback(() => getScheduleDetailByScheduleId(scheduleId), []) as any;

  const getCurrentSchedule = useCallback(() => getCurrentScheduleDetailsByAppId(applicationId), []) as any;

  const getApplicationDetail = useCallback(() => getCurrentApplicationDetailsByAppId(applicationId), []) as any;

  return (
    <ScheduleUpdateConfirmationUncontrolled
      urlProps={urlProps}
      getScheduleDetail={getScheduleDetail}
      getCurrentSchedule={getCurrentSchedule}
      getApplicationDetail={getApplicationDetail}
      bundle={bundle}
      stage={stage}
    />
  );
}
