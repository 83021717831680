import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { buildUrl, CANDIDATE_SUPPORT_LINK } from 'src/config/urls';
import { isPastAppointmentError } from 'src/helpers/error.helpers';
import { Stage } from 'src/helpers/get-settings';
import {
  AvailableTimeSlots,
  RescheduleAppointmentRequest,
  RescheduleAppointmentResponse,
  CancelAppointmentRequest,
  CancelAppointmentResponse,
  TimeSlot,
  validNHEPooling,
  AppointmentType,
} from 'src/utility/appointment-data';
import { getFormattedText, getText } from 'src/utility/common';
import { AppointmentSlots } from './appointment-slots';

interface AppointmentListControlledProps {
  applicationId: string;
  timeSlots?: AvailableTimeSlots;
  curTimeSlot?: TimeSlot;
  rescheduleAppointmentAccessor: (
    request: RescheduleAppointmentRequest
  ) => Promise<RescheduleAppointmentResponse | undefined>;
  cancelAppointmentAccessor: (
    request: CancelAppointmentRequest
  ) => Promise<CancelAppointmentResponse | undefined>;
  applicationState?: string;
  isDragonspineEnabled?: boolean;
  trackAppointmentUpdateClick: () => void;
  checklistURL: string;
  candidateDashURL: string;
  loading: boolean;
  error: boolean;
  errorCode?: string | number;
  bundle: MessageBundle | undefined;
  stage: Stage;
  appointmentType: AppointmentType;
  jobId: string;
}

export function AppointmentListControlled({
  applicationId,
  timeSlots,
  curTimeSlot,
  rescheduleAppointmentAccessor,
  cancelAppointmentAccessor,
  applicationState,
  isDragonspineEnabled,
  trackAppointmentUpdateClick,
  checklistURL,
  candidateDashURL,
  error,
  loading,
  bundle,
  stage,
  appointmentType,
  jobId,
  errorCode,
}: AppointmentListControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(bundle, 'banner-error-candidate-support', {
        support: supportLink,
      })}
    </MessageBanner>
  );

  if (
    (!curTimeSlot || errorCode)
    && (isPastAppointmentError(errorCode)
      || !validNHEPooling(applicationState, stage, appointmentType))
  ) {
    // No reserved appointment in the future found
    return null;
  }

  if (error || !timeSlots) {
    return errorMessage;
  }

  return (
    <AppointmentSlots
      applicationId={applicationId}
      timeSlots={timeSlots}
      curTimeSlot={curTimeSlot}
      rescheduleAppointmentAccessor={rescheduleAppointmentAccessor}
      cancelAppointmentAccessor={cancelAppointmentAccessor}
      isDragonspineEnabled={isDragonspineEnabled}
      trackAppointmentUpdateClick={trackAppointmentUpdateClick}
      checklistURL={checklistURL}
      candidateDashURL={candidateDashURL}
      bundle={bundle}
      stage={stage}
      appointmentType={appointmentType}
      jobId={jobId}
    />
  );
}
