import React, { createRef, useState } from 'react';
import './styles.scss';

interface IVideoProps {
  src: string;
  controls: boolean;
  type: string;
  crossOrigin: string;
}

export const VideoComponent: React.FC<IVideoProps> = ({
  src,
  controls,
  type,
  crossOrigin,
}) => {
  const [showControls, setShowControls] = useState<boolean>(false);
  const videoEl = createRef<HTMLVideoElement>();
  const onShowControl = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    videoEl?.current?.play();
    setShowControls(true);
  };

  return (
    <div className="video-container">
      {!showControls && (
        <div className="play-button">
          <button onClick={onShowControl}>
            <img
              src="https://m.media-amazon.com/images/G/01/HVH-CandidateApplication/video_play_button.svg"
              alt="Unable to load"
            />
          </button>
        </div>
      )}
      <video
        ref={videoEl}
        controls={controls || showControls}
        crossOrigin={
          crossOrigin as 'anonymous' | 'use-credentials' | '' | undefined
        }
      >
        <source src={src} type={type} />
      </video>
    </div>
  );
};

export default VideoComponent;
