import { AwsRum } from 'aws-rum-web';

const getInteractionId = (event: Event): string => {
  const eventPath = event.composedPath() as Element[];
  for (const element of eventPath) {
    if (element.hasAttribute && element.hasAttribute('data-rum-id')) {
      return element.getAttribute('data-rum-id') as string;
    }
  }
  return '';
};

const telemetries = [
  ['errors'],
  ['http'],
  ['performance'],
  [
    'interaction',
    {
      events: [{ event: 'click', element: document }],
      interactionId: getInteractionId,
    },
  ],
];

interface AwsRumClientProps {
  applicationId?: string;
  applicationVersion?: string;
  applicationRegion?: string;
  identityPoolId?: string;
  guestRoleArn?: string;
}

export class AwsRumClient {
  private static awsRum?: AwsRum;

  public static getInstance(props?: AwsRumClientProps) {
    if (!this.awsRum) {
      if (
        props?.applicationId
        && props?.applicationRegion
        && props?.applicationVersion
        && props?.identityPoolId
        && props?.guestRoleArn
      ) {
        this.awsRum = new AwsRum(
          props.applicationId,
          props.applicationVersion,
          props.applicationRegion,
          {
            sessionSampleRate: 1,
            guestRoleArn: props.guestRoleArn,
            identityPoolId: props.identityPoolId,
            telemetries,
            allowCookies: true,
            enableXRay: true,
            endpoint: `https://dataplane.rum.${props.applicationRegion}.amazonaws.com`,
            disableAutoPageView: true,
          },
        );
        this.awsRum.enable();
      }
    }
    return this.awsRum;
  }

  public static recordError(error: any) {
    AwsRumClient.getInstance()?.recordError(error);
  }

  public static recordEvent(name: string, data: any) {
    AwsRumClient.getInstance()?.recordEvent(name, data);
  }

  public static recordPageView(
    pageId: string,
    url?: string,
    applicationId?: string,
    jobId?: string,
  ) {
    AwsRumClient.getInstance()?.recordPageView({
      pageId,
      pageAttributes: {
        applicationId: applicationId || '',
        jobId: jobId || '',
        url: url || '',
      },
    });
  }
}
