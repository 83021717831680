/* eslint-disable max-len */
import { Stage } from '../helpers/get-settings';

export type QueryParam = string | string[] | undefined;

export interface UrlProps {
  stage?: Stage;
  applicationId?: QueryParam;
  jobId?: QueryParam;
  scheduleId?: QueryParam;
  locale?: QueryParam;
  appointmentType?: QueryParam;
  appointmentTypePath?: QueryParam;
}

const KNOWN_PARAMETERS = [
  'applicationId',
  'jobId',
  'scheduleId',
  'locale',
  'appointmentType',
  'appointmentTypePath',
];

const KNOWN_SESSION_ATTRIBUTES_PARAMS = {
  startFromAtoZ: '1',
};

// ---------------- Internal pages ----------------
export const APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL = '/selfservice/appointment/:appointmentTypePath?/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_CURRENT_SCHEDULE_URL = '/selfservice/schedule/current-schedule/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL = '/selfservice/schedule/available-schedule/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_CONFIRMATION_URL = '/selfservice/schedule/update-schedule-confirmation/:scheduleId/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_CONFIRMATION_URL = '/selfservice/schedule/cancel-schedule-confirmation/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_COMPLETE_URL = '/selfservice/schedule/update-schedule-complete/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL = '/selfservice/schedule/update-schedule-failure/:applicationId/:jobId';

export const SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_COMPLETE_URL = '/selfservice/schedule/cancel-schedule-complete/:applicationId/:jobId';

export const SELF_SERVICE_CANCEL_CONFIRMATION_URL = '/selfservice/cancel/appointment/:appointmentTypePath/confirmation/:applicationId/:jobId';

export const SELF_SERVICE_CANCEL_COMPLETE_URL = '/selfservice/cancel/appointment/:appointmentTypePath/cancel-complete/:applicationId/:jobId';

export const SELF_SERVICE_UPDATE_FAILURE_URL = '/selfservice/cancel/appointment/:appointmentTypePath/update-failure/:applicationId/:jobId';

// ---------------- API ---------------------------
export const GET_CURRENT_TIME_SLOT_API_BY_APPT_TYPE = '/api/appointment/:applicationId/:locale/time-slot?appointmentType=:appointmentType';
export const GET_AVAILABLE_TIME_SLOTS_BY_APPOINTMENT_TYPE_API = '/api/appointment/:applicationId/:locale/available-time-slots?appointmentType=:appointmentType';
export const RESCHEDULE_APPOINTMENT = '/api/appointment/reschedule';
export const CANCEL_APPOINTMENT = '/api/appointment/cancel';
export const HEALTH_CHECK = '/api/health/';
export const GET_CURRENT_SCHEDULE_DETAILS_API = '/api/schedule/details/:applicationId/:locale/byAppId';
export const GET_CURRENT_APPLICATION_DETAILS_API = '/api/schedule/details/application/:applicationId';
export const GET_AVAILABLE_SCHEDULES_API = '/api/schedule/available';
export const GET_SCHEDULE_DETAILS_BY_SCHEDULE_ID_API = '/api/schedule/details/:scheduleId/:locale/byScheduleId';
export const UPDATE_SCHEDULE_API = '/api/schedule/update';

// ---------------- External links ----------------
export const PAGE_NOT_FOUND_URL: Record<Stage, string> = {
  local: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  beta: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'beta-ca': 'https://beta-ca.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'beta-jp': 'https://beta-jp.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  gamma: 'https://gamma-us.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'gamma-ca': 'https://gamma-ca.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'gamma-mx': 'https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'gamma-eu': 'https://gamma-uk.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'gamma-jp': 'https://gamma-jp.devo.jobsatamazon.hvh.a2z.com/page-not-found',
  'preprod-us': 'https://us.preprod.jobsatamazon.hvh.a2z.com/page-not-found',
  'preprod-ca': 'https://ca.preprod.jobsatamazon.hvh.a2z.com/page-not-found',
  'preprod-mx': 'https://mx.preprod.jobsatamazon.hvh.a2z.com/page-not-found',
  'preprod-eu': 'https://uk.preprod.jobsatamazon.hvh.a2z.com/page-not-found',
  'preprod-jp': 'https://jp.preprod.jobsatamazon.hvh.a2z.com/page-not-found',
  'prod-us': 'https://hiring.amazon.com/page-not-found',
  'prod-ca': 'https://hiring.amazon.ca/page-not-found',
  'prod-mx': 'https://trabaja.amazon.com.mx/page-not-found',
  'prod-eu': 'https://www.jobsatamazon.co.uk/page-not-found',
  'prod-jp': 'https://www.shigoto.amazon.co.jp/page-not-found',
};

export const CHECKLIST_URL: Record<Stage, string> = {
  local:
    'https://beta-us.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  beta: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'beta-ca':
    'https://beta-ca.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'beta-jp':
    'https://beta-jp.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  gamma:
    'https://gamma-us.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'gamma-ca':
    'https://gamma-ca.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'gamma-mx':
    'https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'gamma-jp':
    'https://gamma-jp.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'gamma-eu':
    'https://gamma-uk.devo.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'preprod-us':
    'https://us.preprod.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'preprod-ca':
    'https://ca.preprod.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'preprod-mx':
    'https://mx.preprod.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'preprod-eu':
    'https://uk.preprod.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'preprod-jp':
    'https://jp.preprod.jobsatamazon.hvh.a2z.com/checklist/{jobId}/{applicationId}',
  'prod-us': 'https://hiring.amazon.com/checklist/{jobId}/{applicationId}',
  'prod-ca': 'https://hiring.amazon.ca/checklist/{jobId}/{applicationId}',
  'prod-mx': 'https://trabaja.amazon.com.mx/checklist/{jobId}/{applicationId}',
  'prod-eu': 'https://www.jobsatamazon.co.uk/checklist/{jobId}/{applicationId}',
  'prod-jp':
    'https://www.shigoto.amazon.co.jp/checklist/{jobId}/{applicationId}',
};

export const CANDIDATE_DASHBOARD_URL: Record<Stage, string> = {
  local: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  beta: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'beta-jp':
    'https://beta-jp.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'beta-ca':
    'https://beta-ca.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  gamma: 'https://gamma-us.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'gamma-mx':
    'https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'gamma-ca':
    'https://gamma-ca.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'gamma-eu':
    'https://gamma-uk.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'gamma-jp':
    'https://gamma-jp.devo.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'preprod-us':
    'https://us.preprod.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'preprod-mx':
    'https://mx.preprod.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'preprod-ca':
    'https://ca.preprod.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'preprod-eu':
    'https://uk.preprod.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'preprod-jp':
    'https://jp.preprod.jobsatamazon.hvh.a2z.com/app#/myApplications',
  'prod-us': 'https://hiring.amazon.com/app#/myApplications',
  'prod-ca': 'https://hiring.amazon.ca/app#/myApplications',
  'prod-mx': 'https://trabaja.amazon.com.mx/app#/myApplications',
  'prod-eu': 'https://www.jobsatamazon.co.uk/app#/myApplications',
  'prod-jp': 'https://www.shigoto.amazon.co.jp/app#/myApplications',
};

export const ADOBE_DATA_LAYER_URL = {
  local:
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  beta: 'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'beta-jp':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'beta-ca':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  gamma:
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'gamma-mx':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'gamma-ca':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'gamma-eu':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'gamma-jp':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'preprod-us':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'preprod-mx':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'preprod-ca':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'preprod-eu':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'preprod-jp':
    'https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js',
  'prod-us':
    'https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js',
  'prod-mx':
    'https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js',
  'prod-eu':
    'https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js',
  'prod-jp':
    'https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js',
  'prod-ca':
    'https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js',
};

export const CANDIDATE_SUPPORT_LINK: Record<Stage, string> = {
  local: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  beta: 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'beta-jp': 'https://beta-us.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'beta-ca': 'https://beta-ca.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  gamma: 'https://gamma-us.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'gamma-mx': 'https://gamma-mx.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'gamma-eu': 'https://gamma-uk.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'gamma-jp': 'https://gamma-jp.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'gamma-ca': 'https://gamma-ca.devo.jobsatamazon.hvh.a2z.com/contact-us#/',
  'preprod-us': 'https://us.preprod.jobsatamazon.hvh.a2z.com/contact-us#/',
  'preprod-mx': 'https://mx.preprod.jobsatamazon.hvh.a2z.com/contact-us#/',
  'preprod-eu': 'https://uk.preprod.jobsatamazon.hvh.a2z.com/contact-us#/',
  'preprod-jp': 'https://jp.preprod.jobsatamazon.hvh.a2z.com/contact-us#/',
  'preprod-ca': 'https://ca.preprod.jobsatamazon.hvh.a2z.com/contact-us#/',
  'prod-us': 'https://hiring.amazon.com/contact-us#/',
  'prod-mx': 'https://trabaja.amazon.com.mx/contact-us#/',
  'prod-eu': 'https://www.jobsatamazon.co.uk/contact-us#/',
  'prod-jp': 'https://www.shigoto.amazon.co.jp/contact-us#/',
  'prod-ca': 'https://hiring.amazon.ca/contact-us#/',
};

export const buildUrl = (
  url: string | Record<Stage, string>,
  { stage, ...params }: UrlProps = {},
): string => {
  let href = typeof url === 'string' ? url : url[stage || ('local' as Stage)];
  KNOWN_PARAMETERS.forEach((paramName) => {
    const value = (params as any)[paramName];
    if (value) {
      href = href
        .replace(`:${paramName}`, value)
        .replace(`{${paramName}}`, value);
    }
  });
  Object.entries(KNOWN_SESSION_ATTRIBUTES_PARAMS).forEach(([key, value]) => {
    if (sessionStorage.getItem(key) === value) {
      href = `${href}${href.includes('?') ? '&' : '?'}${key}=true`;
    }
  });
  return href;
};
