import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { getCurrentScheduleDetailsByAppId } from 'src/utility/schedule-data';
import { ScheduleCancelConfirmationUncontrolled } from './card-uncontrolled';

interface ScheduleCancelConfirmationProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function ScheduleCancelConfirmation({
  bundle,
  stage,
}: ScheduleCancelConfirmationProps) {
  const { applicationId, jobId, scheduleId } = useParams<{
  applicationId: string;
  jobId: string;
  scheduleId: string;
  }>();

  const urlProps = {
    applicationId,
    jobId,
    scheduleId,
  } as UrlProps;

  const getScheduleDetail = useCallback(() => getCurrentScheduleDetailsByAppId(applicationId), []) as any;

  return (
    <ScheduleCancelConfirmationUncontrolled
      urlProps={urlProps}
      getScheduleDetail={getScheduleDetail}
      bundle={bundle}
      stage={stage}
    />
  );
}
