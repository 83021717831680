import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { getCurrentRtwTimeSlotByAppId } from '../../utility/appointment-data';
import { CancelConfirmationUncontrolled } from './card-uncontrolled';

interface CancelConfirmationProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function CancelConfirmation({
  bundle,
  stage,
}: CancelConfirmationProps) {
  const { applicationId, jobId } = useParams<{
  applicationId: string;
  jobId: string;
  }>();

  const urlProps = {
    applicationId,
    jobId,
  } as UrlProps;

  const getAppointmentDetail = useCallback(() => getCurrentRtwTimeSlotByAppId(applicationId), []) as any;

  return (
    <CancelConfirmationUncontrolled
      urlProps={urlProps}
      getRtwDetail={getAppointmentDetail}
      bundle={bundle}
      stage={stage}
    />
  );
}
