import {
  buildUrl,
  GET_AVAILABLE_SCHEDULES_API,
  GET_CURRENT_APPLICATION_DETAILS_API,
  GET_CURRENT_SCHEDULE_DETAILS_API,
  GET_SCHEDULE_DETAILS_BY_SCHEDULE_ID_API,
  UPDATE_SCHEDULE_API,
  UrlProps,
} from '../config/urls';
import { fetcher } from '../helpers/fetcher';
import { getLocale } from './locale-data';

export interface UpdateScheduleRequest {
  applicationId: string;
  jobId: string;
  locale: string;
  scheduleId?: string;
}

export interface UpdateScheduleResponse {
  errorCode?: number;
  status?: string;
}

export interface Schedule {
  errorCode?: number;
  errorMessage?: string;
  scheduleId: string;
  externalJobTitle: string;
  hoursPerWeek: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  basePay: number;
  shiftDifferential: number;
  currencyCode: string;
  signOnBonus?: number;
  signOnBonusL10N?: string;
  scheduleBannerText?: string;
  image: string;
  scheduleText: string;
  briefJobDescription: string;
  firstDayOnSite: string;
  firstDayOnSiteL10N: string;
  startTime: string;
  employmentType: string;
  employmentTypeL10N: string;
  jobPreviewVideo: string;
  jobDescription: string;
  totalPayRate: string;
  totalPayRateL10N: string;
  monthlyBasePay: string;
  monthlyBasePayL10N: string;
  requiredLanguage?: string | Array<string>;
  parsedTrainingDate?: string;
}

export interface GetAvailableScheduleResponse {
  errorCode?: number;
  schedules: Array<Array<Schedule>>;
}

export interface Range {
  maximum: number;
  minimum: number;
}

export interface RangeFilters {
  hoursPerWeek: Range;
  monStartTime: Range;
  monEndTime: Range;
  tueStartTime: Range;
  tueEndTime: Range;
  wedStartTime: Range;
  wedEndTime: Range;
  thuStartTime: Range;
  thuEndTime: Range;
  friStartTime: Range;
  friEndTime: Range;
  satStartTime: Range;
  satEndTime: Range;
  sunStartTime: Range;
  sunEndTime: Range;
}

export interface Sorter {
  fieldName: string;
  ascending: boolean;
}

export interface GetAvailableScheduleRequest {
  applicationId: string;
  jobId: string;
  locale: string;
  sorters?: Array<Sorter>;
  rangeFilters?: RangeFilters;
}

export interface Reason {
  text: string;
  value: string;
  label: string;
}

export interface Appointment {
  timeslotId: string;
  venueId: string;
  nheSource: string;
}

export interface Application {
  errorCode?: number;
  errorMessage?: string;
  applicationId: string;
  candidateId: string;
  scheduleId: string;
  jobId: string;
  nheAppointments: Array<Appointment>;
  isDragonspineEnabled: boolean;
  isSubmitted: boolean;
  step?: string;
  substep?: string;
  applicationState: string;
}

export interface TimeOption {
  time: string;
  value: number;
  index: number;
  short?: string;
  morning?: boolean;
}
export interface SorterOption {
  titleText: string;
  value: string;
  ascending: boolean;
  label: string;
}

export interface TimeRange {
  fromTime: number;
  toTime: number;
  fromTimeIndex: number;
  toTimeIndex: number;
}

export interface WeekdayRange {
  startTime: TimeRange;
  endTime: TimeRange;
}

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const desiredHoursPerWeekOptions = [
  { time: 'Up to 40 hours', value: 40, index: 0 },
  { time: 'Up to 30 hours', value: 30, index: 0 },
  { time: 'Up to 20 hours', value: 20, index: 0 },
  { time: 'Up to 10 hours', value: 10, index: 0 },
] as Array<TimeOption>;

export const desiredFromTimeOptions = [
  {
    time: '12:00 AM', value: 0, index: 0, short: '12:00', morning: true,
  },
  {
    time: '01:00 AM', value: 60, index: 1, short: '01:00', morning: true,
  },
  {
    time: '02:00 AM', value: 120, index: 2, short: '02:00', morning: true,
  },
  {
    time: '03:00 AM', value: 180, index: 3, short: '03:00', morning: true,
  },
  {
    time: '04:00 AM', value: 240, index: 4, short: '04:00', morning: true,
  },
  {
    time: '05:00 AM', value: 300, index: 5, short: '05:00', morning: true,
  },
  {
    time: '06:00 AM', value: 360, index: 6, short: '06:00', morning: true,
  },
  {
    time: '07:00 AM', value: 420, index: 7, short: '07:00', morning: true,
  },
  {
    time: '08:00 AM', value: 480, index: 8, short: '08:00', morning: true,
  },
  {
    time: '09:00 AM', value: 540, index: 9, short: '09:00', morning: true,
  },
  {
    time: '10:00 AM', value: 600, index: 10, short: '10:00', morning: true,
  },
  {
    time: '11:00 AM', value: 660, index: 11, short: '11:00', morning: true,
  },
  {
    time: '12:00 PM', value: 720, index: 12, short: '12:00', morning: false,
  },
  {
    time: '01:00 PM', value: 780, index: 13, short: '01:00', morning: false,
  },
  {
    time: '02:00 PM', value: 840, index: 14, short: '02:00', morning: false,
  },
  {
    time: '03:00 PM', value: 900, index: 15, short: '03:00', morning: false,
  },
  {
    time: '04:00 PM', value: 960, index: 16, short: '04:00', morning: false,
  },
  {
    time: '05:00 PM', value: 1020, index: 17, short: '05:00', morning: false,
  },
  {
    time: '06:00 PM', value: 1080, index: 18, short: '06:00', morning: false,
  },
  {
    time: '07:00 PM', value: 1140, index: 19, short: '07:00', morning: false,
  },
  {
    time: '08:00 PM', value: 1200, index: 20, short: '08:00', morning: false,
  },
  {
    time: '09:00 PM', value: 1260, index: 21, short: '09:00', morning: false,
  },
  {
    time: '10:00 PM', value: 1320, index: 22, short: '10:00', morning: false,
  },
  {
    time: '11:00 PM', value: 1380, index: 23, short: '11:00', morning: false,
  },
] as Array<TimeOption>;

export const desiredToTimeOptions = [
  {
    time: '01:00 AM', value: 60, index: 0, short: '01:00', morning: true,
  },
  {
    time: '02:00 AM', value: 120, index: 1, short: '02:00', morning: true,
  },
  {
    time: '03:00 AM', value: 180, index: 2, short: '03:00', morning: true,
  },
  {
    time: '04:00 AM', value: 240, index: 3, short: '04:00', morning: true,
  },
  {
    time: '05:00 AM', value: 300, index: 4, short: '05:00', morning: true,
  },
  {
    time: '06:00 AM', value: 360, index: 5, short: '06:00', morning: true,
  },
  {
    time: '07:00 AM', value: 420, index: 6, short: '07:00', morning: true,
  },
  {
    time: '08:00 AM', value: 480, index: 7, short: '08:00', morning: true,
  },
  {
    time: '09:00 AM', value: 540, index: 8, short: '09:00', morning: true,
  },
  {
    time: '10:00 AM', value: 600, index: 9, short: '10:00', morning: true,
  },
  {
    time: '11:00 AM', value: 660, index: 10, short: '11:00', morning: true,
  },
  {
    time: '12:00 PM', value: 720, index: 11, short: '12:00', morning: false,
  },
  {
    time: '01:00 PM', value: 780, index: 12, short: '01:00', morning: false,
  },
  {
    time: '02:00 PM', value: 840, index: 13, short: '02:00', morning: false,
  },
  {
    time: '03:00 PM', value: 900, index: 14, short: '03:00', morning: false,
  },
  {
    time: '04:00 PM', value: 960, index: 15, short: '04:00', morning: false,
  },
  {
    time: '05:00 PM', value: 1020, index: 16, short: '05:00', morning: false,
  },
  {
    time: '06:00 PM', value: 1080, index: 17, short: '06:00', morning: false,
  },
  {
    time: '07:00 PM', value: 1140, index: 18, short: '07:00', morning: false,
  },
  {
    time: '08:00 PM', value: 1200, index: 19, short: '08:00', morning: false,
  },
  {
    time: '09:00 PM', value: 1260, index: 20, short: '09:00', morning: false,
  },
  {
    time: '10:00 PM', value: 1320, index: 21, short: '10:00', morning: false,
  },
  {
    time: '11:00 PM', value: 1380, index: 22, short: '11:00', morning: false,
  },
  {
    time: '11:59 PM', value: 1439, index: 23, short: '11:59', morning: false,
  },
] as Array<TimeOption>;

export const initialRangeFilter = [
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
  {
    startTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
    endTime: {
      fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
    },
  },
] as Array<WeekdayRange>;

export const initialState = {
  startTime: {
    fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
  },
  endTime: {
    fromTime: 0, toTime: 1439, fromTimeIndex: 0, toTimeIndex: 23,
  },
} as WeekdayRange;

export const initialSwitchStatus = [true, true, true, true, true, true, true];

export const sorterState = [
  {
    titleText: 'Featured',
    label: 'sorter-schedule-option-1',
  },
  {
    titleText: 'Pay rate - Highest to lowest',
    value: 'totalPayRateMin',
    ascending: false,
    label: 'sorter-schedule-option-2',
  },
  {
    titleText: 'Hours - Most to least',
    value: 'hoursPerWeek',
    ascending: false,
    label: 'sorter-schedule-option-3',
  },
  {
    titleText: 'Hours - Least to most',
    value: 'hoursPerWeek',
    ascending: true,
    label: 'sorter-schedule-option-4',
  },
  {
    titleText: 'Start date - Earliest to latest',
    value: 'firstDayOnSite',
    ascending: true,
    label: 'sorter-schedule-option-5',
  },
] as Array<SorterOption>;

export const updateScheduleReasons = [
  {
    text: 'I can no longer make my current start date',
    value: 'reschedule-start-date-does-not-work',
    label: 'schedule-update-reason-1',
  },
  {
    text: "I can't make my current work schedule",
    value: 'reschedule-work-schedule-does-not-work',
    label: 'schedule-update-reason-2',
  },
  {
    text: 'These locations do not work for me',
    value: 'reschedule-locations-do-not-work',
    label: 'schedule-update-reason-3',
  },
  {
    text: "I don't like my current work schedule",
    value: 'reschedule-do-not-like-current-schedule',
    label: 'schedule-update-reason-4',
  },
  {
    text: "I don't like my current start date",
    value: 'reschedule-do-not-like-start-date',
    label: 'schedule-update-reason-5',
  },
  {
    text: "I don't like my current location",
    value: 'reschedule-do-not-like-current-location',
    label: 'schedule-update-reason-6',
  },
  {
    text: 'None of the above',
    value: 'reschedule-none-of-the-above',
    label: 'schedule-update-or-cancel-common-reason-1',
  },
] as Array<Reason>;

export const cancelScheduleReasons = [
  {
    text: 'I found/accepted an opportunity outside of Amazon',
    value: 'cancellation-found-an-opportunity-outside-amazon',
    label: 'schedule-cancellation-reason-1',
  },
  {
    text: 'I found/accepted another opportunity at Amazon',
    value: 'cancellation-found-another-opportunity-at-amazon',
    label: 'schedule-cancellation-reason-2',
  },
  {
    text: 'I no longer want to work for Amazon',
    value: 'cancellation-no-longer-want-to-work-at-amazon',
    label: 'schedule-cancellation-reason-3',
  },
  {
    text: 'I cannot find a time/schedule that works for me',
    value: 'cancellation-no-working-schedule',
    label: 'schedule-cancellation-reason-4',
  },
  {
    text: 'I cannot find a start date that works for me',
    value: 'cancellation-cannot-find-start-date',
    label: 'schedule-cancellation-reason-5',
  },
  {
    text: 'This location does not work for me',
    value: 'cancellation-location-does-not-work',
    label: 'schedule-cancellation-reason-6',
  },
  {
    text: 'None of the above',
    value: 'cancellation-none-of-the-above',
    label: 'schedule-update-or-cancel-common-reason-1',
  },
] as Array<Reason>;

export const poolingStates = [
  'POOLING',
  'PENDING_APPT_1_SCHEDULING',
  'EVALUATION_PENDING',
];

export function constructGetAvailableScheduleRequest(
  rangeFilters: Array<WeekdayRange>,
  toggleSwitchStatus: Array<boolean>,
  sorterStatus: string,
  desiredHoursPerWeek: TimeOption,
  urlProps: UrlProps,
) {
  const defaultSorter = [
    {
      fieldName: 'priorityRank',
      ascending: true,
    },
    {
      fieldName: 'financeWeekStartDate',
      ascending: true,
    },
  ];
  const sorterOption = sorterState.filter(
    (a) => a.titleText === sorterStatus,
  )[0];
  if (sorterOption.value) {
    defaultSorter.push({
      fieldName: sorterOption.value,
      ascending: sorterOption.ascending,
    });
  }
  const singleDayStartTimeRange = (index: number) => ({
    maximum: toggleSwitchStatus[index]
      ? rangeFilters[index].startTime.toTime
      : 0,
    minimum: toggleSwitchStatus[index]
      ? rangeFilters[index].startTime.fromTime
      : 0,
  });

  const singleDayEndTimeRange = (index: number) => ({
    maximum: toggleSwitchStatus[index]
      ? rangeFilters[index].endTime.toTime
      : 0,
    minimum: toggleSwitchStatus[index]
      ? rangeFilters[index].endTime.fromTime
      : 0,
  });

  const defaultRangeFilter = {
    hoursPerWeek: { maximum: desiredHoursPerWeek.value, minimum: 0 },
    monStartTime: singleDayStartTimeRange(0),
    monEndTime: singleDayEndTimeRange(0),
    tueStartTime: singleDayStartTimeRange(1),
    tueEndTime: singleDayEndTimeRange(1),
    wedStartTime: singleDayStartTimeRange(2),
    wedEndTime: singleDayEndTimeRange(2),
    thuStartTime: singleDayStartTimeRange(3),
    thuEndTime: singleDayEndTimeRange(3),
    friStartTime: singleDayStartTimeRange(4),
    friEndTime: singleDayEndTimeRange(4),
    satStartTime: singleDayStartTimeRange(5),
    satEndTime: singleDayEndTimeRange(5),
    sunStartTime: singleDayStartTimeRange(6),
    sunEndTime: singleDayEndTimeRange(6),
  };
  return {
    applicationId: urlProps.applicationId,
    jobId: urlProps.jobId,
    locale: getLocale(),
    sorters: defaultSorter,
    rangeFilters: defaultRangeFilter,
  } as GetAvailableScheduleRequest;
}

let cachedRequestsForCurrentScheduleDetails: Record<
string,
Promise<Schedule>
> = {};

let cachedRequestsForCurrentApplicationDetails: Record<
string,
Promise<Application>
> = {};

let cachedRequestsForScheduleDetailsByScheduleId: Record<
string,
Promise<Schedule>
> = {};

export const deleteCachedRequest = () => {
  cachedRequestsForCurrentScheduleDetails = {};
  cachedRequestsForCurrentApplicationDetails = {};
  cachedRequestsForScheduleDetailsByScheduleId = {};
};

export const getCurrentScheduleDetailsByAppId = (
  applicationId?: string,
): Promise<Schedule> => {
  if (!applicationId) {
    throw new Error('applicationId should be provided');
  }
  const locale = getLocale();
  if (!cachedRequestsForCurrentScheduleDetails[applicationId]) {
    const url = buildUrl(GET_CURRENT_SCHEDULE_DETAILS_API, {
      applicationId,
      locale,
    });
    cachedRequestsForCurrentScheduleDetails[applicationId] = fetcher(url);
  }
  return cachedRequestsForCurrentScheduleDetails[applicationId];
};

export const getCurrentApplicationDetailsByAppId = (
  applicationId?: string,
): Promise<Application> => {
  if (!applicationId) {
    throw new Error('applicationId should be provided');
  }
  if (!cachedRequestsForCurrentApplicationDetails[applicationId]) {
    const url = buildUrl(GET_CURRENT_APPLICATION_DETAILS_API, {
      applicationId,
    });
    cachedRequestsForCurrentApplicationDetails[applicationId] = fetcher(url);
  }
  return cachedRequestsForCurrentApplicationDetails[applicationId];
};

export const getAvailableSchedules = (
  request?: GetAvailableScheduleRequest,
): Promise<GetAvailableScheduleResponse> => {
  if (!request) {
    throw new Error('request should be provided');
  }
  return fetcher(buildUrl(GET_AVAILABLE_SCHEDULES_API), {
    method: 'POST',
    body: JSON.stringify(request),
  });
};

export const updateOrCancelSchedule = (
  request?: UpdateScheduleRequest,
): Promise<UpdateScheduleResponse> => {
  if (!request) {
    throw new Error('request should be provided');
  }
  return fetcher(buildUrl(UPDATE_SCHEDULE_API), {
    method: 'POST',
    body: JSON.stringify(request),
  });
};

export const getScheduleDetailByScheduleId = (
  scheduleId?: string,
): Promise<Schedule> => {
  if (!scheduleId) {
    throw new Error('scheduleId should be provided');
  }
  const locale = getLocale();
  if (!cachedRequestsForScheduleDetailsByScheduleId[scheduleId]) {
    const url = buildUrl(GET_SCHEDULE_DETAILS_BY_SCHEDULE_ID_API, {
      scheduleId,
      locale,
    });
    cachedRequestsForScheduleDetailsByScheduleId[scheduleId] = fetcher(url);
  }
  return cachedRequestsForScheduleDetailsByScheduleId[scheduleId];
};
