import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { Schedule } from 'src/utility/schedule-data';
import { ScheduleCancelConfirmationControlled } from './card-controlled';

interface ScheduleCancelConfirmationUncontrolledProps {
  getScheduleDetail: () => Promise<Schedule | undefined>;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleCancelConfirmationUncontrolled({
  getScheduleDetail,
  urlProps,
  bundle,
  stage,
}: ScheduleCancelConfirmationUncontrolledProps) {
  const [currentScheduleDetails, setCurrentScheduleDetails] = useState<
  Schedule | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const requestBackend = useCallback(async () => {
    setLoading(true);
    try {
      const details = await getScheduleDetail();
      if (details?.errorCode) {
        setError(true);
      } else {
        setCurrentScheduleDetails(details);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    requestBackend();
  }, []);

  return (
    <ScheduleCancelConfirmationControlled
      loading={loading}
      error={error}
      details={currentScheduleDetails}
      urlProps={urlProps}
      bundle={bundle}
      stage={stage}
    />
  );
}
