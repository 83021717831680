import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Card } from '@amzn/stencil-react-components/card';
import { Flyout } from '@amzn/stencil-react-components/flyout';
import { Select } from '@amzn/stencil-react-components/form';
import { IconCross, IconRefresh } from '@amzn/stencil-react-components/icons';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { ToggleSwitch } from '@amzn/stencil-react-components/toggle-switch';
import { UrlProps } from 'src/config/urls';
import { deepCopy, getFormattedText, getText } from 'src/utility/common';
import {
  days,
  desiredFromTimeOptions,
  desiredHoursPerWeekOptions,
  desiredToTimeOptions,
  initialRangeFilter,
  initialState,
  initialSwitchStatus,
  TimeOption,
} from 'src/utility/schedule-data';
import { createDateFromTimeOnly } from 'src/utility/time';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import { Label } from '../typography';
import './styles.scss';

interface FilterFlyoutProps {
  isFilterFlyoutOpen: boolean;
  closeFilterFlyout: () => void;
  desiredHoursPerWeek: any;
  setDesiredHoursPerWeek: any;
  rangeFilters: any;
  setRangeFilters: any;
  toggleSwitchStatus: any;
  setToggleSwitchStatus: any;
  applyButtonClick: any;
  setApplyButtonClick: any;
  bundle: MessageBundle | undefined;
  urlProps: UrlProps;
}

export function FilterFlyout({
  isFilterFlyoutOpen,
  closeFilterFlyout,
  desiredHoursPerWeek,
  setDesiredHoursPerWeek,
  rangeFilters,
  setRangeFilters,
  toggleSwitchStatus,
  setToggleSwitchStatus,
  applyButtonClick,
  setApplyButtonClick,
  bundle,
  urlProps,
}: FilterFlyoutProps) {
  const desiredHoursRenderOption = (option: any) => (
    <Row gridGap="1rem" alignItems="center">
      {getFormattedText(bundle, 'filter-desired-hours-option', {
        0: option.value,
      })}
    </Row>
  );

  const timeRenderOption = (option: any) => (
    <Row gridGap="1rem" alignItems="center">
      {getFormattedText(bundle, 'date-format-timeA', {
        dataParam: createDateFromTimeOnly(
          getFormattedText(
            bundle,
            option.morning
              ? 'filter-schedule-preferences-morning-time'
              : 'filter-schedule-preferences-afternoon-time',
            {
              time: option.short,
            },
          ),
        ),
      })}
    </Row>
  );

  const reset = () => {
    const newRangeFilter = deepCopy(initialRangeFilter);
    setRangeFilters(newRangeFilter);
    const toggleStatus = deepCopy(initialSwitchStatus);
    setToggleSwitchStatus(toggleStatus);
    setDesiredHoursPerWeek(desiredHoursPerWeekOptions[0]);
  };

  const closeAndApply = () => {
    closeFilterFlyout();
    setApplyButtonClick(!applyButtonClick);
    adobeAnalytics.addEventMetric(
      window,
      'apply filter on self service page',
      urlProps.applicationId as string,
      urlProps.jobId as string,
    );
  };

  const weekDaySchedules = (weekday: string, index: number) => (
    <Col
      key={weekday + index}
      border="groove"
      padding="1rem"
      gridGap="1rem"
      className="weekday-preference"
    >
      <Row
        alignItems="center"
        gridGap="1rem"
        className="weekday-toggle-switch"
      >
        <ToggleSwitch
          checked={toggleSwitchStatus[index]}
          onChange={(on: boolean) => {
            const newStatus = [...toggleSwitchStatus];
            newStatus[index] = on;
            setToggleSwitchStatus(newStatus);

            const newFilter = [...rangeFilters];
            newFilter[index] = deepCopy(initialState);
            setRangeFilters(newFilter);
          }}
        />
        <Text fontSize="T200">
          {getText(bundle, `filter-schedule-preferences-${weekday}`)}
        </Text>
      </Row>
      <Col>
        <Text fontSize="T200">
          {getText(bundle, 'filter-schedule-preferences-start')}
        </Text>
        <Row
          alignItems="center"
          gridGap="1rem"
          justifyContent="space-between"
        >
          <Col gridGap="0.5rem" width="50%">
            <Label>{getText(bundle, 'filter-schedule-preferences-from')}</Label>
            <Select
              disabled={!toggleSwitchStatus[index]}
              onChange={(option: TimeOption) => {
                const newArr = [...rangeFilters];
                const { startTime } = newArr[index];
                startTime.fromTime = option.value;
                startTime.fromTimeIndex = option.index;
                startTime.toTimeIndex = option.index;
                startTime.toTime = desiredToTimeOptions[startTime.toTimeIndex].value;
                setRangeFilters(newArr);
              }}
              options={desiredFromTimeOptions}
              renderOption={timeRenderOption}
              value={
                desiredFromTimeOptions[
                  rangeFilters[index].startTime.fromTimeIndex
                ]
              }
            />
          </Col>
          <Col gridGap="0.5rem" width="50%">
            <Label>{getText(bundle, 'filter-schedule-preferences-To')}</Label>
            <Select
              disabled={!toggleSwitchStatus[index]}
              onChange={(option: TimeOption) => {
                const newArr = [...rangeFilters];
                const { startTime } = newArr[index];
                startTime.toTime = option.value;
                startTime.toTimeIndex = option.index;
                setRangeFilters(newArr);
              }}
              options={desiredToTimeOptions.slice(
                rangeFilters[index].startTime.fromTimeIndex,
              )}
              renderOption={timeRenderOption}
              value={
                desiredToTimeOptions[rangeFilters[index].startTime.toTimeIndex]
              }
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Text fontSize="T200">
          {getText(bundle, 'filter-schedule-preferences-end')}
        </Text>
        <Row
          alignItems="center"
          justifyContent="space-between"
          gridGap="1rem"
        >
          <Col width="50%" gridGap="0.5rem">
            <Label>{getText(bundle, 'filter-schedule-preferences-from')}</Label>
            <Select
              disabled={!toggleSwitchStatus[index]}
              onChange={(option: TimeOption) => {
                const newArr = [...rangeFilters];
                const { endTime } = newArr[index];
                endTime.fromTime = option.value;
                endTime.fromTimeIndex = option.index;

                endTime.toTimeIndex = option.index;
                endTime.toTime = desiredToTimeOptions[endTime.toTimeIndex].value;
                setRangeFilters(newArr);
              }}
              options={desiredFromTimeOptions}
              renderOption={timeRenderOption}
              value={
                desiredFromTimeOptions[
                  rangeFilters[index].endTime.fromTimeIndex
                ]
              }
            />
          </Col>
          <Col gridGap="0.5rem" width="50%">
            <Label>{getText(bundle, 'filter-schedule-preferences-To')}</Label>
            <Select
              disabled={!toggleSwitchStatus[index]}
              onChange={(option: TimeOption) => {
                const newArr = [...rangeFilters];
                const { endTime } = newArr[index];
                endTime.toTime = option.value;
                endTime.toTimeIndex = option.index;
                setRangeFilters(newArr);
              }}
              options={desiredToTimeOptions.slice(
                rangeFilters[index].endTime.fromTimeIndex,
              )}
              renderOption={timeRenderOption}
              value={
                desiredToTimeOptions[rangeFilters[index].endTime.toTimeIndex]
              }
            />
          </Col>
        </Row>
      </Col>
    </Col>
  );

  return (
    <Flyout isOpen={isFilterFlyoutOpen} close={closeFilterFlyout}>
      <Col className="flyout">
        <Card justifyContent="space-between" alignItems="center">
          <Button
            icon={<IconRefresh title="iconRefresh" />}
            variant={ButtonVariant.Tertiary}
            onClick={reset}
            className="reset-filter-button-one"
          />
          <Text fontSize="T400">{getText(bundle, 'button-filter')}</Text>
          <Button
            icon={<IconCross title="iconRefresh" />}
            variant={ButtonVariant.Tertiary}
            onClick={closeFilterFlyout}
            className="close-filter-button"
          />
        </Card>

        <Col overflow="auto" padding="1rem" gridGap="1rem">
          <Col gridGap="0.5rem" className="desired-hour-selection">
            <Label>{getText(bundle, 'filter-desired-hours-label')}</Label>
            <Select
              onChange={setDesiredHoursPerWeek}
              value={desiredHoursPerWeek}
              options={desiredHoursPerWeekOptions}
              renderOption={desiredHoursRenderOption}
            />
          </Col>

          <Col gridGap="0.5rem" className="schedule-preference-selection">
            <Label>
              {getText(bundle, 'filter-schedule-preferences-label')}
            </Label>
            {days.map((day, index) => weekDaySchedules(day, index))}
          </Col>
        </Col>

        <Card justifyContent="space-around" alignItems="center">
          <Row gridGap="1rem">
            <Button onClick={reset} className="reset-filter-button-two">
              {getText(bundle, 'button-reset')}
            </Button>
            <Button
              onClick={closeAndApply}
              variant={ButtonVariant.Primary}
              className="apply-filter-button"
            >
              {getText(bundle, 'button-apply')}
            </Button>
          </Row>
        </Card>
      </Col>
    </Flyout>
  );
}
