import { Stage } from './get-settings';

// todo: extract this feature config into CDK / external resource independent of code

export const shouldRedirectToAppointmentSelfServiceAfterScheduling = (
  stage: Stage,
) => ![
  'beta',
  'gamma',
  'gamma-ca',
  'beta-ca',
  'preprod-us',
  'preprod-ca',
  'prod-us',
  'prod-ca',
  'beta-jp',
  'gamma-jp',
  'preprod-jp',
  'prod-jp',
].includes(stage);
