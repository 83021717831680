import React, { useEffect, useState } from 'react';
import { buildUrl, HEALTH_CHECK } from 'src/config/urls';
import { fetcherWithRawResponse } from 'src/helpers/fetcher';
import { getURLParams } from 'src/utility/common';

export type AuthOrRedirectProps = {
  children: JSX.Element;
};

/**
 * This code is _not_ authentication and doesn't constitute security!
 * Do not use for this purpose!
 *
 * This component only prevents assets from rendering and sends the user to the signin-page for user experience.
 * All assets are still public and the user can disregard the redirect if they so choose.
 * Verification of the user's auth token is done on the server side before returning any data.
 */

function mapOldDomainToNewDomain(oldRequest: URL) {
  let newHostname: string;
  const oldHostName = oldRequest.hostname;
  if (oldHostName.startsWith('beta')) {
    newHostname = 'beta-us.devo.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('gamma-mx')) {
    newHostname = 'gamma-mx.devo.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('gamma-eu')) {
    newHostname = 'gamma.devo.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('gamma-jp')) {
    newHostname = 'gamma-jp.devo.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('gamma')) {
    newHostname = 'gamma-us.devo.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('preprod-us')) {
    newHostname = 'us.preprod.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('preprod-mx')) {
    newHostname = 'mx.preprod.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('preprod-eu')) {
    newHostname = 'uk.preprod.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('preprod-jp')) {
    newHostname = 'jp.preprod.jobsatamazon.hvh.a2z.com';
  } else if (oldHostName.startsWith('prod-us')) {
    newHostname = 'hiring.amazon.com';
  } else if (oldHostName.startsWith('prod-mx')) {
    newHostname = 'trabaja.amazon.com.mx';
  } else if (oldHostName.startsWith('prod-eu')) {
    newHostname = 'www.jobsatamazon.co.uk';
  } else {
    newHostname = 'hiring.amazon.com';
  }

  const newUrl = new URL(oldRequest.toString());
  newUrl.hostname = newHostname;
  return newUrl;
}

export function AuthOrRedirect(props: AuthOrRedirectProps) {
  const [renderChildren, setRenderChildren] = useState(false);
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [redirectToPageNotFound, setRedirectToPageNotFound] = useState(false);

  const pageUrl = new URL(window.location.href);
  if (pageUrl.hostname.endsWith('css.associate.amazondelivers.jobs')) {
    window.location.href = mapOldDomainToNewDomain(pageUrl).toString();
    return <div />;
  }

  useEffect(() => {
    setRenderChildren(false);
    setRenderRedirect(false);
    setRedirectToPageNotFound(false);
    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === 'development') {
      if (process.env.REACT_APP_USE_MOCK_REQUESTS === 'true') {
        console.warn('skipping checkHealth');
        setRenderChildren(true);
        return;
      }
    }

    async function checkHealth() {
      try {
        const params = getURLParams(window.location.href);
        if (!params.jobId.startsWith('JOB')) {
          setRedirectToPageNotFound(true);
          return;
        }
        const url = buildUrl(`${HEALTH_CHECK + params.applicationId}/${params.jobId}`);
        const response = await fetcherWithRawResponse(url);
        if (response.ok) {
          setRenderChildren(true);
        } else if (response.status === 409) {
          setRedirectToPageNotFound(true);
        } else {
          setRenderRedirect(true);
        }
      } catch (err) {
        setRenderRedirect(true);
      }
    }
    checkHealth();
  }, []);

  if (redirectToPageNotFound) {
    if (process.env.NODE_ENV === 'development') {
      return (
        <h1>
          Authentication failed. Please check if local server is running
          properly
        </h1>
      );
    }
    window.location.href = '/page-not-found';
    return <div />;
  }

  if (renderChildren) {
    return props.children;
  }

  if (renderRedirect) {
    if (process.env.NODE_ENV === 'development') {
      return (
        <h1>
          Auth missing or invalid - not redirecting you because this is local
          dev. Sign in and put your token in `accessToken` in localStorage
        </h1>
      );
    }
    window.location.href = `/app#/login?redirectUrl=${encodeURIComponent(
      window.location.href,
    )}`;
  }
  return <div />;
}
