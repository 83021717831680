import { getSettings, Settings } from '../get-settings';
import { AwsRumClient } from './awsRumClient';

export const initAwsRumClient = async () => {
  const settings: Settings = await getSettings();
  return AwsRumClient.getInstance({
    applicationId: settings?.awsRumApplicationId,
    applicationVersion: settings?.awsRumApplicationVersion,
    applicationRegion: settings?.awsRumMonitorRegion,
    identityPoolId: settings?.awsRumIdentityPoolId,
    guestRoleArn: settings?.awsRumGuestRoleArn,
  });
};
