import React, { useCallback } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { useParams } from 'react-router-dom';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import {
  GetAvailableScheduleRequest,
  getAvailableSchedules,
} from 'src/utility/schedule-data';
import { ScheduleListUncontrolled } from './card-uncontrolled';

interface ScheduleListProps {
  bundle: MessageBundle;
  stage: Stage;
}

export function ScheduleList({ bundle, stage }: ScheduleListProps) {
  const { applicationId, jobId } = useParams<{
  applicationId: string;
  jobId: string;
  }>();

  const urlProps = {
    applicationId,
    jobId,
  } as UrlProps;

  const getAvailableSchedulesAccessor = useCallback(
    (request: GetAvailableScheduleRequest) => getAvailableSchedules(request),
    [],
  ) as any;

  return (
    <ScheduleListUncontrolled
      getAvailableSchedulesAccessor={getAvailableSchedulesAccessor}
      urlProps={urlProps}
      bundle={bundle}
      stage={stage}
    />
  );
}
