import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { Schedule } from 'src/utility/schedule-data';
import { ScheduleCardControlled } from './card-controlled';

interface ScheduleCardUncontrolledProps {
  currentScheduleDetailsAccessor: () => Promise<Schedule | undefined>;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleCardUncontrolled({
  currentScheduleDetailsAccessor,
  urlProps,
  bundle,
  stage,
}: ScheduleCardUncontrolledProps) {
  const [currentScheduleDetails, setCurrentScheduleDetails] = useState<
  Schedule | undefined
  >();
  const [isApplicationInReleasedState, setIsApplicationInReleasedState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const requestBackend = useCallback(async () => {
    setLoading(true);
    try {
      const details = await currentScheduleDetailsAccessor();
      if (details?.errorMessage === 'MISSING_SCHEDULE_ID_IN_APPLICATION') {
        setIsApplicationInReleasedState(true);
        setCurrentScheduleDetails(details);
      } else if (details?.errorCode) {
        setError(true);
      } else {
        setCurrentScheduleDetails(details);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    requestBackend();
  }, []);

  return (
    <ScheduleCardControlled
      currentScheduleDetails={currentScheduleDetails}
      loading={loading}
      error={error}
      isApplicationInReleasedState={isApplicationInReleasedState}
      urlProps={urlProps}
      bundle={bundle}
      stage={stage}
    />
  );
}
