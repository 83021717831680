import * as Cookie from 'js-cookie';
import { ADOBE_DATA_LAYER_URL } from '../config/urls';
import { getSettings } from '../helpers/get-settings';

interface MetricsWindow {
  dataLayerArray?: any[];
}

const WORKFLOW = 'BB NACF Tier-1 Node Workflow';

getSettings().then((settings) => {
  const script = document.createElement('script');
  script.setAttribute('src', ADOBE_DATA_LAYER_URL[settings.stage]);
  script.setAttribute('defer', '');
  script.setAttribute('async', '');
  document.body.appendChild(script);
});

const pushLayer = (window: MetricsWindow, metric: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(metric);
  }
  // eslint-disable-next-line no-param-reassign
  window.dataLayerArray = window.dataLayerArray || [];
  window.dataLayerArray.push(metric);
};

export async function addPageLoadMetric(
  window: MetricsWindow,
  name: string,
  applicationId?: string,
  jobId?: string,
  props?: any,
) {
  const metric = {
    application: {
      ID: applicationId,
      workflow: WORKFLOW,
    },
    job: {
      ID: jobId,
    },
    event: 'page load',
    page: {
      name,
      type: 'application',
      locale: Cookie.get('hvh-locale'),
    },
    ...props,
  };

  pushLayer(window, metric);
}

export async function addEventMetric(
  window: MetricsWindow,
  event: string,
  applicationId?: string,
  jobId?: string,
  props?: any,
) {
  const metric = {
    application: {
      ID: applicationId,
      workflow: WORKFLOW,
    },
    job: {
      ID: jobId,
    },
    event,
    page: {
      locale: Cookie.get('hvh-locale'),
    },
    ...props,
  };

  pushLayer(window, metric);
}
