import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import { PageContainer } from '@amzn/stencil-react-components/page';
import { Text } from '@amzn/stencil-react-components/text';
import { useParams } from 'react-router-dom';
import { pageBackgroundColor } from 'src/config/palette';
import {
  buildUrl,
  CANDIDATE_DASHBOARD_URL,
  CANDIDATE_SUPPORT_LINK,
  CHECKLIST_URL,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { AwsRumClient } from 'src/helpers/rum/awsRumClient';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getFormattedText, getText } from 'src/utility/common';

interface ScheduleSelfServiceUpdateScheduleFailureRouteProps {
  stage: Stage;
  bundle: MessageBundle;
}

export function ScheduleSelfServiceUpdateScheduleFailureRoute({
  stage,
  bundle,
}: ScheduleSelfServiceUpdateScheduleFailureRouteProps) {
  const { applicationId, jobId } = useParams<{
  applicationId: string;
  jobId: string;
  }>();

  AwsRumClient.recordPageView(
    'css-update-schedule-failure',
    window.location.href,
    applicationId,
    jobId,
  );

  const urlToChecklist = buildUrl(CHECKLIST_URL, {
    stage,
    jobId,
    applicationId,
  });

  const urlToCandidateDashboard = buildUrl(CANDIDATE_DASHBOARD_URL, {
    stage,
  });

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  return (
    <PageContainer
      backgroundColor={pageBackgroundColor}
      alignItems="center"
      paddingTop={32}
      paddingHorizontal={16}
      paddingBottom={64}
    >
      <Col alignItems="center">
        <Col
          width="100%"
          maxWidth="672px"
          gridGap="1rem"
          className="schedule-update-failed"
        >
          <Text fontSize="T400">
            {getText(bundle, 'heading-page-update-schedule-failure')}
          </Text>
          <Text fontSize="T300">
            {getFormattedText(
              bundle,
              'subheading-page-update-schedule-failure',
              {
                support: supportLink,
              },
            )}
          </Text>
          <Button
            variant={ButtonVariant.Primary}
            onClick={useNavigatorWithURLReady(urlToChecklist)}
            className="ash-redirect-button"
          >
            {getText(bundle, 'button-continue-next-step')}
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={useNavigatorWithURLReady(urlToCandidateDashboard)}
            className="candidate-dashboard-redirect-button"
          >
            {getText(bundle, 'button-to-candidate-dashboard')}
          </Button>
        </Col>
      </Col>
    </PageContainer>
  );
}
