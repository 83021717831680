import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Stage } from 'src/helpers/get-settings';
import { SimplifiedTimeSlot } from 'src/utility/appointment-data';
import { getFormattedText } from 'src/utility/common';
import { convertToYYYYMMDDHHmm } from 'src/utility/time';
import { getAppointmentDetails } from '../appointment-card/appointment-details-helper';
import calendar from '../appointment-card/calendar.png';
import './styles.scss';

interface AppointmentConfirmationProps {
  stage: Stage;
  languages: string;
  timeSlot?: SimplifiedTimeSlot;
  bundle?: MessageBundle;
}

export function AppointmentConfirmation({
  timeSlot,
  bundle,
  stage,
  languages,
}: AppointmentConfirmationProps) {
  const date = convertToYYYYMMDDHHmm(
    `${timeSlot?.startDate} ${timeSlot?.startTime}`,
  );

  return (
    <Col width="100%">
      <Row justifyContent="center" alignItems="center" gridGap="1rem">
        <Col justifyContent="center">
          <img
            src={calendar}
            alt="Calendar"
            width="80px"
            aria-hidden="true"
          />
          <Text className="calendar-day" fontSize="35px">
            {getFormattedText(bundle, 'date-format-day', {
              dataParam: date,
            })}
          </Text>
          <Text className="calendar-month" fontSize="12px">
            {getFormattedText(bundle, 'date-format-month-short', {
              dataParam: date,
            })}
          </Text>
        </Col>
        <Col margin={{ right: -10 }}>
          {timeSlot
            && getAppointmentDetails(timeSlot, bundle, stage, languages, 'T200')}
        </Col>
      </Row>
    </Col>
  );
}
