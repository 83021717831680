import React from 'react';
import {
  createAsyncMessageBundleManager,
  LocalizationContextBuilder,
} from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import ReactDOM from 'react-dom';
import './index.scss';
import arbManifest from '../public/translations/arbManifest';
import App from './App';
import { AuthOrRedirect } from './components/auth/redirect';
import { initAwsRumClient } from './helpers/rum/rumClientFactory';
import reportWebVitals from './reportWebVitals';
import { DEFAULT_LOCALE_US, getLocale } from './utility/locale-data';

(async () => {
  await initAwsRumClient();

  const localizationContextProvider = () => new LocalizationContextBuilder()
    .withLocale(getLocale())
    .withDefaultLocale(DEFAULT_LOCALE_US)
    .build();

  const mbm = createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveBundleUrl: (url: string | undefined) => (url
      ? `/selfservice/translations/${url}`
      : '/selfservice/translations/translation.json'),
  });

  // Remove this after upgrading to React >= 18.
  // SIM: https://sim.amazon.com/issues/T-JOYCE-666
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <MbmProvider mbm={mbm}>
      <React.StrictMode>
        <AuthOrRedirect>
          <App />
        </AuthOrRedirect>
      </React.StrictMode>
    </MbmProvider>,
    document.getElementById('CSSContentRoot'),
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
})();
