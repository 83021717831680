import { getCurrentCountryCode, getSettings } from './get-settings';

const DEFAULT_TIMEOUT = 10000;

function handleErrors(response: Response, url: string) {
  if (!response.ok) {
    throw Error(
      `Http error when requesting ${url}: ${response.status}, ${response.statusText}`,
    );
  }
  return response;
}

export const plainFetcher = async (url: string, props?: any) => {
  const settings = await getSettings();

  const newProps = { ...props };
  newProps.headers = {
    'X-CSS-Authorization': window.localStorage.getItem('accessToken'),
    Country: getCurrentCountryCode(),
    ...newProps.headers,
  };

  const response = await fetch((settings.apiEndpoint ?? '') + url, newProps);
  return handleErrors(response, url);
};

export const fetcher = async (url: string, props?: any) => {
  const response = await plainFetcher(url, props);
  return response.json();
};

export const fetcherWithRawResponse = async (url: string, props?: any) => {
  const settings = await getSettings();
  const newProps = { ...props };
  newProps.headers = {
    'X-CSS-Authorization': window.localStorage.getItem('accessToken'),
    ...newProps.headers,
  };

  return fetch((settings.apiEndpoint ?? '') + url, newProps);
};

export const fetchWithTimeout = (
  url: string,
  props?: any,
  timeout = DEFAULT_TIMEOUT,
) => Promise.race([
  fetcher(url, props),
  new Promise((_, reject) => {
    setTimeout(() => reject(new Error('Timeout')), timeout);
  }),
]);
