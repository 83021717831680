import React, { useCallback, useEffect, useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { UrlProps } from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { deepCopy } from 'src/utility/common';
import {
  constructGetAvailableScheduleRequest,
  desiredHoursPerWeekOptions,
  GetAvailableScheduleRequest,
  GetAvailableScheduleResponse,
  initialRangeFilter,
  initialSwitchStatus,
  Schedule,
  sorterState,
  TimeOption,
  WeekdayRange,
} from 'src/utility/schedule-data';
import { ScheduleListControlled } from './card-controlled';
import { FilterFlyout } from './filter-flyout';
import { SortFlyout } from './sort-flyout';

interface ScheduleListUncontrolledProps {
  urlProps: UrlProps;
  getAvailableSchedulesAccessor: (
    request: GetAvailableScheduleRequest
  ) => Promise<GetAvailableScheduleResponse | undefined>;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleListUncontrolled({
  getAvailableSchedulesAccessor,
  urlProps,
  bundle,
  stage,
}: ScheduleListUncontrolledProps) {
  const [isFilterFlyoutOpen, setIsFilterFlyoutOpen] = useState<boolean>(false);
  const [isSortFlyoutOpen, setIsSortFlyoutOpen] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<Array<Array<Schedule>>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const closeFilterFlyout = useCallback(() => {
    setIsFilterFlyoutOpen(false);
  }, [setIsFilterFlyoutOpen]);

  const openFilterFlyout = useCallback(() => {
    setIsFilterFlyoutOpen(true);
  }, [setIsFilterFlyoutOpen]);

  const closeSortFlyout = useCallback(() => {
    setIsSortFlyoutOpen(false);
  }, [setIsSortFlyoutOpen]);

  const openSortFlyout = useCallback(() => {
    setIsSortFlyoutOpen(true);
  }, [setIsSortFlyoutOpen]);

  const [desiredHoursPerWeek, setDesiredHoursPerWeek] = useState<TimeOption>(
    desiredHoursPerWeekOptions[0],
  );
  const [rangeFilters, setRangeFilters] = useState<Array<WeekdayRange>>(
    deepCopy(initialRangeFilter),
  );
  const [toggleSwitchStatus, setToggleSwitchStatus] = useState<Array<boolean>>(
    deepCopy(initialSwitchStatus),
  );

  const [sorterStatus, setSorterStatus] = useState(sorterState[0].titleText);

  const [applyButtonClick, setApplyButtonClick] = useState<boolean>(false);

  const requestBackend = useCallback(async () => {
    setLoading(true);
    try {
      const request = constructGetAvailableScheduleRequest(
        rangeFilters,
        toggleSwitchStatus,
        sorterStatus,
        desiredHoursPerWeek,
        urlProps,
      );
      const res = await getAvailableSchedulesAccessor(request);
      if (res?.errorCode) {
        setError(true);
      } else {
        setSchedules(res?.schedules);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, [toggleSwitchStatus, rangeFilters, sorterStatus, desiredHoursPerWeek]);

  useEffect(() => {
    requestBackend();
  }, [applyButtonClick]);

  return (
    <Col>
      <ScheduleListControlled
        openFilterFlyout={openFilterFlyout}
        openSortFlyout={openSortFlyout}
        schedules={schedules}
        error={error}
        loading={loading}
        urlProps={urlProps}
        bundle={bundle}
        stage={stage}
      />
      <SortFlyout
        isSortFlyoutOpen={isSortFlyoutOpen}
        closeSortFlyout={closeSortFlyout}
        sorterStatus={sorterStatus}
        setSorterStatus={setSorterStatus}
        applyButtonClick={applyButtonClick}
        setApplyButtonClick={setApplyButtonClick}
        urlProps={urlProps}
        bundle={bundle}
      />
      <FilterFlyout
        isFilterFlyoutOpen={isFilterFlyoutOpen}
        closeFilterFlyout={closeFilterFlyout}
        desiredHoursPerWeek={desiredHoursPerWeek}
        setDesiredHoursPerWeek={setDesiredHoursPerWeek}
        rangeFilters={rangeFilters}
        setRangeFilters={setRangeFilters}
        toggleSwitchStatus={toggleSwitchStatus}
        setToggleSwitchStatus={setToggleSwitchStatus}
        applyButtonClick={applyButtonClick}
        setApplyButtonClick={setApplyButtonClick}
        urlProps={urlProps}
        bundle={bundle}
      />
    </Col>
  );
}
