const defaultTextColor = '#22303e';
const labelColor = '#56616c';
const appointmentBannerBackgroundColor = '#ebf5fd';
const pageBackgroundColor = '#f2f4f7';
const scheduleCardBannerColor = '#ecf4fc';
const surgePayBannerColor = '#56616c';
const surgePayBannerTextColor = '#FFFFFF';
const signOnBonusBackgroundColor = '#1768c9';

export {
  defaultTextColor,
  labelColor,
  appointmentBannerBackgroundColor,
  pageBackgroundColor,
  scheduleCardBannerColor,
  surgePayBannerColor,
  surgePayBannerTextColor,
  signOnBonusBackgroundColor,
};
