import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Col } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { isPastAppointmentError } from 'src/helpers/error.helpers';
import { Stage } from 'src/helpers/get-settings';
import {
  AppointmentType,
  TimeSlot,
  validNHEPooling,
} from 'src/utility/appointment-data';
import { getFormattedText, getText } from 'src/utility/common';
import {
  getLanguageSupported,
  spokenLanguageAlternativesToLocaleList,
} from 'src/utility/locale-data';
import { AppointmentBanner } from './appointment-banner';

interface AppointmentCardControlledProps {
  timeSlot?: TimeSlot;
  appointmentType?: AppointmentType;
  applicationState?: string;
  loading: boolean;
  error: boolean;
  errorCode?: string | number;
  checklistURL: string;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function AppointmentCardControlled({
  timeSlot,
  appointmentType,
  applicationState,
  error,
  loading,
  checklistURL,
  bundle,
  stage,
  errorCode,
}: AppointmentCardControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const checklistLink = (
    <Link key="checklistLink" href={checklistURL}>
      {getText(bundle, 'banner-error-expired-appointment-link-title')}
    </Link>
  );

  const appointmentInThePastErrorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(bundle, 'banner-error-expired-appointment', {
        link: checklistLink,
      })}
    </MessageBanner>
  );

  const noValidNheErrorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(bundle, 'banner-error-reschedule-appointment-no-valid-state', {
        link: checklistLink,
      })}
    </MessageBanner>
  );

  if (error || !timeSlot) {
    console.info('Error fetching time slot', errorCode);
    if (isPastAppointmentError(errorCode)) return appointmentInThePastErrorMessage;
    if (!validNHEPooling(applicationState, stage, appointmentType)) return noValidNheErrorMessage;
  }

  const languages = getLanguageSupported(
    spokenLanguageAlternativesToLocaleList(timeSlot?.spokenLocaleAlternatives),
    bundle,
  );

  return (
    <AppointmentBanner
      timeSlot={timeSlot}
      applicationState={applicationState}
      languages={languages}
      bundle={bundle}
      stage={stage}
    />
  );
}
