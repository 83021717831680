import React from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import {
  buildUrl,
  CANDIDATE_SUPPORT_LINK,
  SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL,
  SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_CONFIRMATION_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import { useNavigatorWithURLReady } from 'src/hooks/use-navigator';
import { getFormattedText, getText } from 'src/utility/common';
import { Schedule } from 'src/utility/schedule-data';
import { ScheduleContainer } from './schedule-container';

interface ScheduleCardControlledProps {
  loading: boolean;
  error: boolean;
  currentScheduleDetails?: Schedule;
  isApplicationInReleasedState: boolean;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleCardControlled({
  loading,
  error,
  currentScheduleDetails,
  isApplicationInReleasedState,
  urlProps,
  bundle,
  stage,
}: ScheduleCardControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(
        bundle,
        'banner-error-current-schedule-fails-to-retrieve',
        {
          support: supportLink,
        },
      )}
    </MessageBanner>
  );

  if (error || !currentScheduleDetails) {
    return errorMessage;
  }

  const navigateToAvailableSchedulePage = useNavigatorWithURLReady(
    buildUrl(SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL, urlProps),
  );

  const navigateToCancelScheduleConfirmationPage = useNavigatorWithURLReady(
    buildUrl(SCHEDULE_SELF_SERVICE_CANCEL_SCHEDULE_CONFIRMATION_URL, urlProps),
  );

  return (
    <Col gridGap="1rem">
      <Text fontSize="T400">
        {getText(bundle, 'heading-page-current-schedule')}
      </Text>
      {!isApplicationInReleasedState && (
        <ScheduleContainer
          schedule={currentScheduleDetails}
          displayPreviewInfo={false}
          bundle={bundle}
          stage={stage}
        />
      )}

      {isApplicationInReleasedState && (
        <MessageBanner type={MessageBannerType.Informational}>
          {getText(bundle, 'heading-page-current-schedule-without-schedule')}
        </MessageBanner>
      )}

      <Button
        className="change-schedule-button"
        variant={ButtonVariant.Primary}
        onClick={navigateToAvailableSchedulePage}
      >
        {getText(bundle, 'button-change-schedule-and-start-date')}
      </Button>
      {!isApplicationInReleasedState && (
        <Button
          className="change-schedule-button"
          variant={ButtonVariant.Secondary}
          onClick={navigateToCancelScheduleConfirmationPage}
        >
          {getText(bundle, 'button-cancel-schedule-and-start-date')}
        </Button>
      )}
    </Col>
  );
}
