import React, { useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { InputWrapper, Select } from '@amzn/stencil-react-components/form';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Link } from '@amzn/stencil-react-components/link';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import {
  APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL,
  buildUrl,
  CANDIDATE_SUPPORT_LINK,
  SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_COMPLETE_URL,
  SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL,
  UrlProps,
} from 'src/config/urls';
import { Stage } from 'src/helpers/get-settings';
import {
  poolingStates,
  Reason,
  Schedule,
  updateOrCancelSchedule,
  updateScheduleReasons,
  UpdateScheduleRequest,
} from 'src/utility/schedule-data';
import { shouldRedirectToAppointmentSelfServiceAfterScheduling } from '../../helpers/features';
import * as adobeAnalytics from '../../utility/adobe-analytics';
import { DEFAULT_APPOINTMENT_TYPE_PATH } from '../../utility/appointment-data';
import { getFormattedText, getText } from '../../utility/common';
import { BackButton } from '../custom-button/back-button';
import { ScheduleContainer } from '../schedule-card/schedule-container';

interface ScheduleUpdateConfirmationControlledProps {
  loading: boolean;
  error: boolean;
  isApplicationInReleasedState: boolean;
  details?: Schedule;
  applicationState?: string;
  urlProps: UrlProps;
  bundle: MessageBundle | undefined;
  stage: Stage;
}

export function ScheduleUpdateConfirmationControlled({
  loading,
  error,
  isApplicationInReleasedState,
  details,
  applicationState,
  urlProps,
  bundle,
  stage,
}: ScheduleUpdateConfirmationControlledProps) {
  if (loading) {
    return (
      <Col alignItems="center">
        <Spinner
          size={SpinnerSize.Small}
          loadingText={getText(bundle, 'page-loading-message')}
          showText
        />
      </Col>
    );
  }

  const candidateSupport = buildUrl(CANDIDATE_SUPPORT_LINK, {
    stage,
  });

  const supportLink = (
    <Link key="supportLink" href={candidateSupport}>
      {getText(bundle, 'banner-error-candidate-support-link-title')}
    </Link>
  );

  const errorMessage = (
    <MessageBanner type={MessageBannerType.Error}>
      {getFormattedText(
        bundle,
        'banner-error-selected-schedule-fails-to-retrieve',
        {
          support: supportLink,
        },
      )}
    </MessageBanner>
  );

  if (error || !details) {
    return errorMessage;
  }

  const [updating, setUpdating] = useState<boolean>(false);
  const [reason, setReason] = useState<Reason>();
  const [reasonError, setReasonError] = useState<boolean>(false);

  const urlToUpdateScheduleCompletePage = buildUrl(
    SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_COMPLETE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const urlToUpdateScheduleFailurePage = buildUrl(
    SCHEDULE_SELF_SERVICE_UPDATE_SCHEDULE_FAILURE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const urlToAvailableSchedulePage = buildUrl(
    SCHEDULE_SELF_SERVICE_AVAILABLE_SCHEDULE_URL,
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
    },
  );

  const urlToAppointmentSelfService = buildUrl(
    // TODO: Remove this temporary solution all instances of the old NHE
    //  selfservice url are updated from "selfservice/appointment/" to "selfservice/appointment/new-hire-event"
    APPOINTMENT_SELF_SERVICE_BY_APPT_TYPE_URL.replace('?', ''),
    {
      applicationId: urlProps.applicationId,
      jobId: urlProps.jobId,
      appointmentTypePath: DEFAULT_APPOINTMENT_TYPE_PATH,
    },
  );

  const nextStep = shouldRedirectToAppointmentSelfServiceAfterScheduling(stage)
    && applicationState
    && poolingStates.includes(applicationState)
    ? urlToAppointmentSelfService
    : urlToUpdateScheduleCompletePage;

  const getFooterText = () => {
    if (reasonError) {
      return getText(bundle, 'banner-error-reason-not-selected');
    }
    return undefined;
  };

  const sendAdobeEvents = (eventName: string, reason: string | undefined) => {
    adobeAnalytics.addEventMetric(
      window,
      eventName,
      urlProps.applicationId as string,
      urlProps.jobId as string,
      reason
        ? {
          cancellationReschedule: {
            reason,
          },
        }
        : null,
    );
  };

  const updateSchedule = async () => {
    if (!reason && !isApplicationInReleasedState) {
      setReasonError(true);
    } else {
      setUpdating(true);
      try {
        const request = {
          applicationId: urlProps.applicationId,
          jobId: urlProps.jobId,
          scheduleId: details.scheduleId,
        } as UpdateScheduleRequest;
        const response = await updateOrCancelSchedule(request);
        if (response?.errorCode) {
          sendAdobeEvents(
            'fail to update shift due to unknown error on self service page',
            reason?.value,
          );
          window.location.assign(urlToUpdateScheduleFailurePage);
        } else {
          sendAdobeEvents(
            'successfully update shift on self service page',
            reason?.value,
          );
          window.location.assign(nextStep);
        }
      } catch (err) {
        sendAdobeEvents(
          'fail to update shift due to unknown error on self service page',
          reason?.value,
        );
        window.location.assign(urlToUpdateScheduleFailurePage);
      }
    }
  };

  const spinner = (
    <Col alignItems="center">
      <Spinner
        size={SpinnerSize.Small}
        loadingText={getText(bundle, 'page-updating-message')}
        showText
      />
    </Col>
  );

  const confirmation = (
    <Col gridGap="1rem">
      <Row alignItems="center">
        <BackButton url={urlToAvailableSchedulePage} bundle={bundle} />
      </Row>
      <ScheduleContainer
        schedule={details}
        displayPreviewInfo
        title={getText(bundle, 'heading-page-update-schedule')}
        bundle={bundle}
        stage={stage}
      />
      {!isApplicationInReleasedState && (
        <Col gridGap="0.5rem" className="reschedule-reason-selection">
          <InputWrapper
            id="update-schedule-reason-wrapper"
            labelText={getText(bundle, 'reason-question-page-update-schedule')}
            footer={getFooterText()}
            error={reasonError}
            required
          >
            {(inputProps) => (
              <Select
                options={updateScheduleReasons}
                renderOption={(option) => getText(bundle, option.label)}
                placeholder={getText(bundle, 'reason-select-placeholder')}
                {...inputProps}
                onChange={(option: Reason) => {
                  setReason(option);
                  setReasonError(false);
                }}
              />
            )}
          </InputWrapper>
        </Col>
      )}

      <Button
        variant={ButtonVariant.Primary}
        onClick={updateSchedule}
        className="confirm-reschedule-button"
      >
        {getText(bundle, 'button-confirm-schedule-selection')}
      </Button>
    </Col>
  );

  return (
    <>
      {updating && spinner}
      {!updating && confirmation}
    </>
  );
}
