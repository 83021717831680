import { Stage } from '../helpers/get-settings';

// Multi-Language Support feature: display required language
export const MLS_REQUIRED_LANGUAGE: Record<Stage, boolean> = {
  local: true,
  beta: true,
  'beta-jp': false,
  'beta-ca': true,
  gamma: true,
  'gamma-ca': true,
  'gamma-mx': false,
  'gamma-eu': false,
  'gamma-jp': false,
  'preprod-us': true,
  'preprod-ca': true,
  'preprod-mx': false,
  'preprod-eu': false,
  'preprod-jp': false,
  'prod-us': true,
  'prod-ca': true,
  'prod-mx': false,
  'prod-eu': false,
  'prod-jp': false,
};

export const DISPLAY_HOURLY_PAYMENT: Record<Stage, boolean> = {
  local: true,
  beta: true,
  'beta-jp': true,
  'beta-ca': true,
  gamma: true,
  'gamma-mx': false,
  'gamma-eu': true,
  'gamma-jp': true,
  'gamma-ca': true,
  'preprod-us': true,
  'preprod-mx': false,
  'preprod-eu': true,
  'preprod-jp': true,
  'preprod-ca': true,
  'prod-us': true,
  'prod-ca': true,
  'prod-mx': false,
  'prod-eu': true,
  'prod-jp': true,
};
