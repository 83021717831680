import React, { useState } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import { Button } from '@amzn/stencil-react-components/button';
import { ExpanderContent } from '@amzn/stencil-react-components/expander';
import { TriggerButton } from '@amzn/stencil-react-components/helpers';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import { SimplifiedTimeSlot } from 'src/utility/appointment-data';
import { getFormattedText, getText } from 'src/utility/common';
import {
  createDateFromTimeOnly,
  getLeftBoundFromRange,
  getRightBoundFromRange,
} from 'src/utility/time';
import { isPhysicalAppointment } from '../appointment-card/appointment-details-helper';

interface ExpanderProps {
  slots?: Array<SimplifiedTimeSlot>;
  timeRange: string;
  onClickButton: (slot: SimplifiedTimeSlot) => void;
  loading: boolean;
  bundle: MessageBundle | undefined;
}

export function Expander({
  slots,
  timeRange,
  onClickButton,
  loading,
  bundle,
}: ExpanderProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const leftBound = createDateFromTimeOnly(getLeftBoundFromRange(timeRange));
  const rightBound = createDateFromTimeOnly(getRightBoundFromRange(timeRange));
  const translatedRange = `${getFormattedText(bundle, 'date-format-time', {
    dataParam: leftBound,
  })
  } - ${
    getFormattedText(bundle, 'date-format-time', {
      dataParam: rightBound,
    })}`;

  if (loading) {
    return (
      <Col padding="1rem" alignItems="center">
        <Spinner size={SpinnerSize.Small} showText={false} />
      </Col>
    );
  }

  return (
    <Col gridGap="0.5rem">
      <Row
        alignItems="center"
        gridGap="0.5rem"
        className="available-range"
      >
        <TriggerButton
          iconAltText="trigger"
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <Text
          className="expanderLabel"
          fontSize="T300"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {translatedRange}
        </Text>
      </Row>
      <ExpanderContent isExpanded={isExpanded}>
        <ul className="slotsContainer" data-testid="slotsContainer">
          {slots
            ?.sort((a, b) => a.startTime.localeCompare(b.startTime))
            ?.map((slot) => (
              <li className="slot" key={slot.timeSlotId}>
                <Col>
                  <Button
                    dataTestId={`select-appointment-button-${slot.timeSlotId}`}
                    style={{ margin: '0px' }}
                    className="select-appointment-button"
                    onClick={() => onClickButton(slot)}
                  >
                    {getFormattedText(bundle, 'date-format-time', {
                      dataParam: createDateFromTimeOnly(slot.startTime),
                    })}
                    {' '}
                    {isPhysicalAppointment(slot.locationType)
                      ? getText(bundle, 'in-person-appointment-label')
                      : getText(bundle, 'virtual-appointment-label')}
                  </Button>
                </Col>
              </li>
            ))}
        </ul>
      </ExpanderContent>
    </Col>
  );
}
